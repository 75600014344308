import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { format } from "date-fns";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  isEmpty,
  isSingleSelect,
  isValidPrice,
  isNumber,
  uploadFile,
  removeFile,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Editor from "../Layout/Editor";
var module = "clientpanel/products/";
var moduleName = "Product";
var modulePath = "/clientpanel/catalog-products";
var dayList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

class Form extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (
      this.props.match.path === "/clientpanel/catalog-products/edit/:EditID"
    ) {
      editID = this.props.match.params.EditID;
    }
    var allowOutlet =
      cookie.load("clientAllowOutlet") !== "" &&
      typeof cookie.load("clientAllowOutlet") !== "undefined" &&
      typeof cookie.load("clientAllowOutlet") !== undefined
        ? cookie.load("clientAllowOutlet")
        : "";
    this.state = {
      editID: editID,
      pageloading: false,
      clientdata: {
        assign_outlet:
          allowOutlet !== "" ? { label: allowOutlet, value: allowOutlet } : "",
        product_type: "",
        category: "",
        product_name: "",
        alias_name: "",
        sku: "",
        tag_info: "",
        min_quantity: "",
        max_quantity: "",
        sequence: "",
        short_description: "",
        long_description: "",
        pos_id: "",
        meta_title: "",
        meta_keywords: "",
        meta_description: "",
        product_disply_source: [],
        price: "",
        cost: "",
        special_price: "",
        special_price_from_date: "",
        special_price_to_date: "",
        lead_time: "",
        lead_time_minutes: "",
        leadtime_basedonqty: "",
        availability_errorinfo: "",
        dayavailability: [
          {
            title: "Current day(date) and time based product availability",
            type: "currentdate",
            data: [
              {
                daybased: [
                  { day: "", from: "", to: "", stock: "", validate: "" },
                ],
              },
              { datebased: [{ from: "", to: "", stock: "", validate: "" }] },
            ],
          },
          {
            title: "Fullfillment day(date) and time based product availability",
            type: "fullfillmentdate",
            data: [
              {
                daybased: [
                  { day: "", from: "", to: "", stock: "", validate: "" },
                ],
              },
              { datebased: [{ from: "", to: "", stock: "", validate: "" }] },
            ],
          },
        ],
        apply_minmax_select: "0",
        comosetup: [
          {
            combo_name: "",
            products: [],
            defaultproduct: "",
            combogroup: [],
            min_select: "0",
            max_select: "0",
            sequence: 1,
            piecescount: "0",
            apply_price: "0",
            apply_modifier: "0",
            multipleselection_apply: "0",
            disable_validation: "0",
            single_selection: "0",
          },
        ],

        assign_availability: [],
        status: [],
        voucher_type: "",
        voucher_expity_date: "",
        voucher_number_date: "",
        product_voucher_points: "",
        voucher_increase_qty: "",
        reedmed_availability: "",
        voucher_food_option: "",
        voucher_food_discount_type: "",
        food_voucher_disc_val: "",
        voucher_free_products: "",
        voucher_included_products: [],
        assign_tags: [],
        stock_min: "",
        stock: "",
        low_stock_alert: "0",
        thumbnail: "",
        gallery: [],
        recommendation: [],
        action: "add",
      },
      btndisabled: false,
      loading: true,
      tatList: [],
      availabiltyList: [],
      availability: [],
      formpost: [],
      companyDetail: [],
      outletList: [],
      productTypeList: [],
      subcategoryList: [],
      simpleProducts: [],
      prdouctGroupList: [],
      productTagList: [],
      listProductSetup: [],
      allowOutlet: allowOutlet,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.loadOutlet();
    this.loadproductTypeList();
    this.loadAvailabilty();
    this.listProductSetup();
    if (this.state.allowOutlet !== "") {
      this.loadSubcategory();
      this.laodprdouctGroupList();
    }

    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + CompanyID() + "&detail_id=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
          var history = this.props.history;
          setTimeout(function () {
            history.push(modulePath);
          }, 1000);
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          var product_status =
            result.product_status == "A" ? "Active" : "In Active";
          var status =
            result.product_status !== "" && result.product_status !== null
              ? {
                  label: product_status,
                  value: result.product_status,
                }
              : "";
          var displysource = [];
          if (
            result.product_disply_source !== "" &&
            result.product_disply_source !== null
          ) {
            result.product_disply_source.split(",").map((item) => {
              displysource.push({ label: item, value: item });
            });
          }

          var dayavailability = this.state.clientdata.dayavailability;
          if (result.time_availability.length > 0) {
            var currentDayTime = [];
            var currentDateTime = [];
            var fullfillmentDayTime = [];
            var fullfillmentDateTime = [];
            result.time_availability.map((item) => {
              var todaystart = new Date();
              var todayend = new Date();

              if (
                item["avbl_based_on"] === "currentdate" &&
                item.avbl_str_time !== "" &&
                item.avbl_str_time !== null &&
                item.avbl_str_time !== "00:00:00"
              ) {
                var splitStartTime = item.avbl_str_time.split(":");
                todaystart.setHours(splitStartTime[0]);
                todaystart.setMinutes(splitStartTime[1]);
                todaystart.setSeconds(splitStartTime[1]);

                var splitEndTime = item.avbl_end_time.split(":");
                todayend.setHours(splitEndTime[0]);
                todayend.setMinutes(splitEndTime[1]);
                todayend.setSeconds(splitEndTime[1]);
                currentDayTime.push({
                  day: item.avbl_days,
                  from:
                    item.avbl_str_time !== "" &&
                    item.avbl_str_time !== null &&
                    item.avbl_str_time !== "00:00:00"
                      ? todaystart
                      : "",
                  to:
                    item.avbl_end_time !== "" &&
                    item.avbl_end_time !== null &&
                    item.avbl_end_time !== "00:00:00"
                      ? todayend
                      : "",
                  stock: item.avbl_stock,
                  validate: item.avbl_stock_validate === "Yes" ? "Yes" : "",
                });
              }

              if (
                item["avbl_based_on"] === "currentdate" &&
                item.avbl_str_datetime !== "" &&
                item.avbl_str_datetime !== null &&
                item.avbl_str_datetime !== "0000-00-00 00:00:00"
              ) {
                currentDateTime.push({
                  from:
                    item.avbl_str_datetime !== "" &&
                    item.avbl_str_datetime !== null &&
                    item.avbl_str_datetime !== "0000-00-00 00:00:00"
                      ? new Date(item.avbl_str_datetime)
                      : "",
                  to:
                    item.avbl_end_datetime !== "" &&
                    item.avbl_end_datetime !== null &&
                    item.avbl_end_datetime !== "00:00:00"
                      ? new Date(item.avbl_end_datetime)
                      : "",
                  stock: item.avbl_stock,
                  validate: item.avbl_stock_validate === "Yes" ? "Yes" : "",
                });
              }

              if (
                item["avbl_based_on"] === "fullfillmentdate" &&
                item.avbl_str_time !== "" &&
                item.avbl_str_time !== null &&
                item.avbl_str_time !== "00:00:00"
              ) {
                var splitStartTime = item.avbl_str_time.split(":");
                todaystart.setHours(splitStartTime[0]);
                todaystart.setMinutes(splitStartTime[1]);
                todaystart.setSeconds(splitStartTime[1]);

                var splitEndTime = item.avbl_end_time.split(":");
                todayend.setHours(splitEndTime[0]);
                todayend.setMinutes(splitEndTime[1]);
                todayend.setSeconds(splitEndTime[1]);
                fullfillmentDayTime.push({
                  day: item.avbl_days,
                  from:
                    item.avbl_str_time !== "" &&
                    item.avbl_str_time !== null &&
                    item.avbl_str_time !== "00:00:00"
                      ? todaystart
                      : "",
                  to:
                    item.avbl_end_time !== "" &&
                    item.avbl_end_time !== null &&
                    item.avbl_end_time !== "00:00:00"
                      ? todayend
                      : "",
                  stock: item.avbl_stock,
                  validate: item.avbl_stock_validate === "Yes" ? "Yes" : "",
                });
              }

              if (
                item["avbl_based_on"] === "fullfillmentdate" &&
                item.avbl_str_datetime !== "" &&
                item.avbl_str_datetime !== null &&
                item.avbl_str_datetime !== "0000-00-00 00:00:00"
              ) {
                fullfillmentDateTime.push({
                  from:
                    item.avbl_str_datetime !== "" &&
                    item.avbl_str_datetime !== null &&
                    item.avbl_str_datetime !== "0000-00-00 00:00:00"
                      ? new Date(item.avbl_str_datetime)
                      : "",
                  to:
                    item.avbl_end_datetime !== "" &&
                    item.avbl_end_datetime !== null &&
                    item.avbl_end_datetime !== "00:00:00"
                      ? new Date(item.avbl_end_datetime)
                      : "",
                  stock: item.avbl_stock,
                  validate: item.avbl_stock_validate === "Yes" ? "Yes" : "",
                });
              }
            });
            dayavailability[0]["data"][0]["daybased"] = currentDayTime;
            dayavailability[0]["data"][1]["datebased"] = currentDateTime;
            dayavailability[1]["data"][0]["daybased"] = fullfillmentDayTime;
            dayavailability[1]["data"][1]["datebased"] = fullfillmentDateTime;
          }

          var product_voucher = "";
          if (result.product_voucher === "f") {
            product_voucher = { label: "Food Voucher", value: "f" };
          } else if (result.product_voucher === "c") {
            product_voucher = { label: "Cash Voucher", value: "c" };
          }
          var voucher_food_option = "";
          if (result.product_voucher_food_option === "1") {
            voucher_food_option = { label: "Normal", value: "1" };
          } else if (result.product_voucher_food_option === "2") {
            voucher_food_option = { label: "Discount", value: "2" };
          }
          var voucher_food_discount_type = "";
          if (result.product_voucher_food_discount_type === "1") {
            voucher_food_discount_type = {
              label: "Normal",
              value: "1",
            };
          } else if (result.product_voucher_food_discount_type === "2") {
            voucher_food_discount_type = {
              label: "Discount",
              value: "2",
            };
          }

          var displyrecommendation = [];
          if (
            result.product_recommendation !== "" &&
            result.product_recommendation !== null
          ) {
            result.product_recommendation.split(",").map((item) => {
              displyrecommendation.push({ label: item, value: item });
            });
          }

          var clientupdatedata = {
            assign_outlet: result.outlet.length > 0 ? result.outlet[0] : "",
            product_type: result.product_type,
            category:
              result.subcategory !== null &&
              Object.keys(result.subcategory).length > 0
                ? result.subcategory
                : "",
            product_name: result.product_name,
            alias_name: result.product_alias,
            sku: result.product_sku,
            tag_info: result.product_tag_info,
            min_quantity: result.product_minimum_quantity,
            max_quantity: result.product_maximum_quantity,
            sequence: result.product_sequence,
            short_description: result.product_short_description,
            long_description: result.product_long_description,
            pos_id: result.product_pos_id,
            meta_title: result.product_meta_title,
            meta_keywords: result.product_meta_keywords,
            meta_description: result.product_meta_description,
            product_disply_source: displysource,
            price: result.product_price,
            cost: result.product_cost,
            special_price:
              result.product_special_price !== null &&
              result.product_special_price !== "0.00"
                ? result.product_special_price
                : "",
            special_price_from_date:
              result.product_special_price_from_date !== "" &&
              result.product_special_price_from_date !== null &&
              result.product_special_price_from_date !== "0000-00-00"
                ? new Date(result.product_special_price_from_date)
                : "",
            special_price_to_date:
              result.product_special_price_to_date !== "" &&
              result.product_special_price_to_date !== null &&
              result.product_special_price_to_date !== "0000-00-00"
                ? new Date(result.product_special_price_to_date)
                : "",
            dayavailability: dayavailability,
            apply_minmax_select: result.product_apply_minmax_select,
            comosetup:
              result.combo_products.length > 0
                ? result.combo_products
                : this.state.clientdata.comosetup,
            assign_availability:
              result.availability.length > 0 ? result.availability : [],
            availability_errorinfo: result.product_availability_errorinfo,

            voucher_type: product_voucher,
            voucher_expity_date:
              result.product_voucher_expiry_date !== "" &&
              result.product_voucher_expiry_date !== null &&
              result.product_voucher_expiry_date !== "0000-00-00"
                ? new Date(result.product_voucher_expiry_date)
                : "",
            voucher_number_date:
              result.product_voucher_number_of_dates !== "" &&
              result.product_voucher_number_of_dates !== null
                ? result.product_voucher_number_of_dates
                : "",
            product_voucher_points:
              result.product_voucher_points !== "" &&
              result.product_voucher_points !== null
                ? result.product_voucher_points
                : "",
            voucher_increase_qty:
              result.product_voucher_increase_qty !== "" &&
              result.product_voucher_increase_qty !== null
                ? result.product_voucher_increase_qty
                : "",
            reedmed_availability: result.voucher_reedmed_availability,
            voucher_food_option: voucher_food_option,
            voucher_food_discount_type: voucher_food_discount_type,
            food_voucher_disc_val:
              result.product_voucher_food_discount_val !== "" &&
              result.product_voucher_food_discount_val !== null
                ? result.product_voucher_food_discount_val
                : "",
            voucher_free_products: result.voucher_free_product,
            voucher_included_products: result.voucher_included_product,

            stock_min:
              result.product_stock_min > 0 ? result.product_stock_min : "",
            stock: result.product_stock,
            low_stock_alert: result.product_stock_alert,
            assign_tags: result.assign_tags,
            thumbnail:
              result.product_thumbnail !== "" &&
              result.product_thumbnail !== null
                ? result.product_thumbnail
                : "",
            gallery:
              result.product_gallery !== "" && result.product_gallery !== null
                ? result.product_gallery.split(",")
                : [],
            recommendation: displyrecommendation,
            status: status,
            action: "edit",
          };
          this.setState(
            { clientdata: clientupdatedata, pageloading: false },
            () => {
              this.loadSubcategory();
              this.laodSimpleProducts();
              this.laodprdouctGroupList();
              this.laodproductTagList();
            }
          );
        } else {
          this.setState({ pageloading: false });
          this.props.history.push(modulePath);
          showAlert("Error", "Invalid Product", "error");
        }
      });
    }
  }
  loadOutlet() {
    var urlShringTxt =
      apiUrl + "clientpanel/outlets/dropdownlist?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ outletList: res.data.result });
      }
    });
  }
  listProductSetup() {
    var urlShringTxt =
      apiUrl + module + "listProductSetup?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        var listProductSetup = [];
        if (res.data.result.length > 0) {
          res.data.result.map((item) => {
            listProductSetup.push({ label: item, value: item });
          });
        }
        this.setState({ listProductSetup: listProductSetup });
      }
    });
  }

  loadproductTypeList() {
    var urlShringTxt =
      apiUrl + module + "productType?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ productTypeList: res.data.result });
      }
    });
  }
  laodSimpleProducts() {
    var storeID = this.state.clientdata.assign_outlet?.value || "";
    if (storeID !== "") {
      var urlShringTxt =
        apiUrl +
        module +
        "simpleproductlist?company_id=" +
        CompanyID() +
        "&storeID=" +
        storeID;
      axios.get(urlShringTxt, clientheaderconfig).then((res) => {
        if (res.data.status === "ok") {
          var resultSet = res.data.result;
          var simpleProducts = [];
          resultSet.map((item) => {
            simpleProducts.push({
              value: item.product_primary_id + "_" + item.product_id,
              label: item.product_name,
            });
          });
          this.setState({ simpleProducts: simpleProducts });
        }
      });
    }
  }
  laodprdouctGroupList() {
    var storeID = this.state.clientdata.assign_outlet?.value || "";
    if (storeID !== "") {
      var urlShringTxt =
        apiUrl +
        "clientpanel/productgroup/dropdownlist?company_id=" +
        CompanyID() +
        "&storeID=" +
        storeID;
      axios.get(urlShringTxt, clientheaderconfig).then((res) => {
        if (res.data.status === "ok") {
          var resultSet = res.data.result;
          this.setState({ prdouctGroupList: resultSet });
        }
      });
    }
  }

  loadSubcategory() {
    var storeID = this.state.clientdata.assign_outlet?.value || "";
    if (storeID !== "") {
      var urlShringTxt =
        apiUrl +
        "clientpanel/subcategory/dropdownlist?company_id=" +
        CompanyID() +
        "&storeID=" +
        storeID;
      axios.get(urlShringTxt, clientheaderconfig).then((res) => {
        if (res.data.status === "ok") {
          this.setState({ subcategoryList: res.data.result });
        }
      });
    }
  }

  loadAvailabilty() {
    var urlShringTxt = apiUrl + "company/settings/availabilty_list";

    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "success") {
        this.setState({ availabiltyList: res.data.result });
      }
    });
  }
  laodproductTagList() {
    var storeID = this.state.clientdata.assign_outlet?.value || "";
    if (storeID !== "") {
      var urlShringTxt =
        apiUrl +
        "clientpanel/tags/dropdownlist?company_id=" +
        CompanyID() +
        "&storeID=" +
        storeID;
      axios.get(urlShringTxt, clientheaderconfig).then((res) => {
        if (res.data.status === "ok") {
          this.setState({ productTagList: res.data.result });
        }
      });
    }
  }

  sateValChange = (field, value) => {
    this.setState({ btndisabled: value });
  };

  handleChange(checked, name) {
    this.setState({ checked });
  }

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { clientdata: { [field]: { $set: value } } }),
      function () {
        if (field === "assign_outlet") {
          this.setState(
            update(this.state, {
              clientdata: {
                category: { $set: "" },
                voucher_free_products: { $set: "" },
                voucher_included_products: { $set: [] },
              },
            })
          );
          this.loadSubcategory();
          this.laodSimpleProducts();
          this.laodprdouctGroupList();
          this.laodproductTagList();
        } else if (field === "product_type") {
          this.loadSubcategory();
          this.laodSimpleProducts();
          this.laodprdouctGroupList();
          this.laodproductTagList();
        }
      }
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.clientdata;

    var assign_availability = [];
    if (postData.assign_availability.length > 0) {
      postData.assign_availability.map((item) => {
        assign_availability.push(item.value);
      });
    }
    var productdisplysource = [];
    if (postData.product_disply_source.length > 0) {
      postData.product_disply_source.map((item) => {
        productdisplysource.push(item.value);
      });
    }

    var product_type =
      Object.keys(postData.product_type).length > 0
        ? postData.product_type.value
        : "";

    var reedmed_availability = [];
    var voucher_included_products = [];
    var voucher_food_option = "";
    if (product_type === "5") {
      if (postData.reedmed_availability.length > 0) {
        postData.reedmed_availability.map((item) => {
          reedmed_availability.push(item.value);
        });
      }
      if (postData.voucher_included_products.length > 0) {
        postData.voucher_included_products.map((item) => {
          var splitPro = item.value.split("_");
          voucher_included_products.push(splitPro);
        });
      }
      voucher_food_option =
        Object.keys(postData.voucher_food_option).length > 0
          ? postData.voucher_food_option.value
          : "";
    }

    var assign_tags = [];
    if (postData.assign_tags.length > 0) {
      postData.assign_tags.map((item) => {
        assign_tags.push(item.value);
      });
    }

    var recommendation = [];
    if (postData.recommendation.length > 0) {
      postData.recommendation.map((item) => {
        recommendation.push(item.value);
      });
    }

    var postObject = {
      product_type: product_type,
      product_name: postData.product_name,
      shop_type: 1,
      alias_name: postData.alias_name,
      sku: postData.sku,
      tag_info: postData.tag_info,
      category:
        Object.keys(postData.category).length > 0
          ? postData.category.value
          : "",
      short_description: postData.short_description,
      long_description: postData.long_description,
      min_quantity: postData.min_quantity,
      max_quantity: postData.max_quantity,
      thumbnail: "",
      status:
        Object.keys(postData.status).length > 0 ? postData.status.value : "A",
      cost: postData.cost,
      price: postData.price,
      special_price: postData.special_price,
      special_price_from_date:
        parseFloat(postData.special_price) > 0 &&
        postData.special_price_from_date !== ""
          ? format(postData.special_price_from_date, "yyyy-MM-dd")
          : "",
      special_price_to_date:
        parseFloat(postData.special_price) > 0 &&
        postData.special_price_to_date !== ""
          ? format(postData.special_price_to_date, "yyyy-MM-dd")
          : "",
      meta_title: postData.meta_title,
      meta_keywords: postData.meta_keywords,
      meta_description: postData.meta_description,
      lead_time: postData.lead_time,
      lead_time_minutes: postData.lead_time_minutes,
      leadtime_basedonqty: postData.leadtime_basedonqty,
      apply_minmax_select:
        postData.apply_minmax_select !== "" ? postData.apply_minmax_select : 0,
      pos_id: postData.pos_id,
      product_disply_source:
        productdisplysource.length > 0 ? productdisplysource.join(",") : "",
      paired_products: "",
      assign_outlet:
        Object.keys(postData.assign_outlet).length > 0
          ? postData.assign_outlet.value
          : "",
      assign_availability:
        assign_availability.length > 0 ? assign_availability.join(",") : "",
      sequence: postData.sequence,
      comboset: product_type === "2" ? JSON.stringify(postData.comosetup) : "",
      availability_errorinfo: postData.availability_errorinfo,
      dayavailability: JSON.stringify(postData.dayavailability),
      voucher_type:
        product_type === "5"
          ? Object.keys(postData.voucher_type).length > 0
            ? postData.voucher_type.value
            : ""
          : "",
      voucher_expity_date:
        product_type === "5"
          ? postData.voucher_expity_date !== ""
            ? format(postData.voucher_expity_date, "yyyy-MM-dd")
            : ""
          : "",
      voucher_number_date:
        product_type === "5" ? postData.voucher_number_date : "",
      reedmed_availability:
        product_type === "5" && reedmed_availability.length > 0
          ? reedmed_availability.join(",")
          : "",
      voucher_food_option: voucher_food_option,
      voucher_food_discount_type:
        product_type === "5"
          ? Object.keys(postData.voucher_food_discount_type).length > 0
            ? postData.voucher_food_discount_type.value
            : ""
          : "",
      food_voucher_disc_val:
        product_type === "5" && voucher_food_option === "2"
          ? postData.food_voucher_disc_val
          : "",
      voucher_free_products:
        product_type === "5"
          ? Object.keys(postData.voucher_free_products).length > 0
            ? postData.voucher_free_products.value
            : ""
          : "",
      voucher_included_products:
        product_type === "5" &&
        voucher_food_option === "2" &&
        voucher_included_products.length > 0
          ? voucher_included_products.join(",")
          : "",
      voucher_increase_qty:
        product_type === "5" ? postData.voucher_increase_qty : "",

      product_voucher_points:
        product_type === "5" ? postData.product_voucher_points : "",

      stock_min: postData.stock_min,
      stock: postData.stock,
      low_stock_alert: postData.low_stock_alert,
      assign_tags: assign_tags.length > 0 ? assign_tags.join(",") : "",
      thumbnail: postData.thumbnail,
      gallery: postData.gallery.length > 0 ? postData.gallery.join(",") : "",
      recommendation: recommendation.length > 0 ? recommendation.join(",") : "",
      status:
        Object.keys(postData.status).length > 0 ? postData.status.value : "A",
      loginID: userID(),
      company_admin_id: clientID(),
      company_id: CompanyID(),
      action: postData.action,
    };

    var post_url = module + "add";
    if (postData.action === "edit" && this.state.editID !== "") {
      postObject["edit_id"] = this.state.editID;
      post_url = module + "update";
    }
    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"catalog-products"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"}{" "}
                      {moduleName}
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={modulePath}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
                <PostForm
                  {...this.props}
                  fields={this.state.clientdata}
                  btndisabled={this.state.btndisabled}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  sateValChange={this.sateValChange}
                  error_msg={this.state.error_msg}
                  outletList={this.state.outletList}
                  availabiltyList={this.state.availabiltyList}
                  productTypeList={this.state.productTypeList}
                  subcategoryList={this.state.subcategoryList}
                  simpleProducts={this.state.simpleProducts}
                  prdouctGroupList={this.state.prdouctGroupList}
                  productTagList={this.state.productTagList}
                  listProductSetup={this.state.listProductSetup}
                  allowOutlet={this.state.allowOutlet}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);

function validationConfig(props) {
  const {
    assign_outlet,
    product_type,
    category,
    product_name,
    sku,
    price,
    cost,
    special_price,
    stock,
    status,
  } = props.fields;

  return {
    fields: [
      "assign_outlet",
      "product_type",
      "category",
      "product_name",
      "sku",
      "price",
      "cost",
      "special_price",
      "stock",
      "status",
    ],

    validations: {
      assign_outlet: [[isSingleSelect, assign_outlet]],
      product_type: [[isSingleSelect, product_type]],
      category: [[isSingleSelect, category]],
      product_name: [[isEmpty, product_name]],
      sku: [[isEmpty, sku]],
      price: [
        [isEmpty, price],
        [isValidPrice, price],
      ],
      cost: [[isValidPrice, cost]],
      special_price: [[isValidPrice, special_price]],
      stock: [
        [isEmpty, stock],
        [isNumber, stock],
      ],
      status: [[isSingleSelect, status]],
    },
  };
}

class PostForm extends Component {
  constructor(props) {
    super(props);
    this.setContent = this.setContent.bind(this);
    this.setLoginContent = this.setLoginContent.bind(this);
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }

  setContent(value) {
    this.props.onChange("short_description", value);
  }
  setLoginContent(value) {
    this.props.onChange("long_description", value);
  }
  handleChangeCheck(name, event) {
    var value = event.target.checked === true ? "1" : "0";
    this.props.onChange(name, value);
  }

  handleChangeCheckBox(type, dayIndex, typebase, elementType, event) {
    this.updateDayAvail(
      type,
      dayIndex,
      typebase,
      elementType,
      event.target.value,
      event.target.checked
    );
  }
  handleChangeAvailDate(type, dayIndex, typebase, elementType, selectDate) {
    this.updateDayAvail(type, dayIndex, typebase, elementType, selectDate);
  }
  handleChangeText(type, dayIndex, typebase, elementType, event) {
    this.updateDayAvail(
      type,
      dayIndex,
      typebase,
      elementType,
      event.target.value
    );
  }
  myFilter(elm) {
    return elm != null && elm !== false && elm !== "";
  }

  updateDayAvail(
    type,
    dayIndex,
    typebase,
    elementType,
    value,
    checkedStatus = null
  ) {
    var dayavailability = this.props.fields.dayavailability;
    var updateddayavailability = [];
    dayavailability.map((item) => {
      if (item.type === type) {
        var updateData = [];
        var daybased = item.data[0].daybased;
        var datebased = item.data[1].datebased;
        if (typebase === "daybased") {
          var updatedDayBase = [];
          daybased.map((dayItems, dayItemIndex) => {
            var updday = dayItems.day;
            var updfrom = dayItems.from;
            var updto = dayItems.to;
            var updstock = dayItems.stock;
            var updvalidate = dayItems.validate;
            if (dayIndex === dayItemIndex) {
              if (elementType === "day") {
                var splitupdday = updday.split(",").filter(this.myFilter);
                if (checkedStatus === true) {
                  splitupdday.push(value);
                } else {
                  const index = splitupdday.indexOf(value);
                  splitupdday.splice(index, 1);
                }
                updday = splitupdday.length > 0 ? splitupdday.join(",") : "";
              } else if (elementType === "fromtime") {
                updfrom = value;
              } else if (elementType === "totime") {
                updto = value;
              } else if (elementType === "stock") {
                updstock = value;
              } else if (elementType === "validate") {
                if (checkedStatus === true) {
                  updvalidate = value;
                } else {
                  updvalidate = "";
                }
              }
            }
            updatedDayBase.push({
              day: updday,
              from: updfrom,
              to: updto,
              stock: updstock,
              validate: updvalidate,
            });
          });
          daybased = updatedDayBase;
        }
        if (typebase === "datebased") {
          var updatedDateBase = [];
          datebased.map((dateItems, dateItemIndex) => {
            var updfrom = dateItems.from;
            var updto = dateItems.to;
            var updstock = dateItems.stock;
            var updvalidate = dateItems.validate;
            if (dayIndex === dateItemIndex) {
              if (elementType === "fromdate") {
                updfrom = value;
              } else if (elementType === "todate") {
                updto = value;
              } else if (elementType === "stock") {
                updstock = value;
              } else if (elementType === "validate") {
                if (checkedStatus === true) {
                  updvalidate = value;
                } else {
                  updvalidate = "";
                }
              }
            }
            updatedDateBase.push({
              from: updfrom,
              to: updto,
              stock: updstock,
              validate: updvalidate,
            });
          });
          datebased = updatedDateBase;
        }
        updateData.push({ daybased: daybased }, { datebased: datebased });
        updateddayavailability.push({
          title: item.title,
          type: item.type,
          data: updateData,
        });
      } else {
        updateddayavailability.push(item);
      }
    });
    this.props.onChange("dayavailability", updateddayavailability);
  }

  addNew(selectIndex, type) {
    var dayavailability = this.props.fields.dayavailability;
    var updateddayavailability = [];
    dayavailability.map((item, index) => {
      if (index === selectIndex) {
        var updateData = [];
        var daybased = item.data[0].daybased;
        var datebased = item.data[1].datebased;
        if (type === "daybased") {
          daybased.push({
            day: "",
            from: "",
            to: "",
            stock: "",
            validate: "",
          });
        } else if (type === "datebased") {
          datebased.push({
            from: "",
            to: "",
            stock: "",
            validate: "",
          });
        }
        updateData.push({ daybased: daybased }, { datebased: datebased });
        updateddayavailability.push({
          title: item.title,
          type: item.type,
          data: updateData,
        });
      } else {
        updateddayavailability.push(item);
      }
    });
    this.props.onChange("dayavailability", updateddayavailability);
  }

  addNewCombo() {
    var comosetup = this.props.fields.comosetup;
    comosetup.push({
      combo_name: "",
      products: [],
      defaultproduct: "",
      combogroup: [],
      min_select: "0",
      max_select: "0",
      sequence: parseInt(this.props.fields.comosetup.length) + 1,
      piecescount: "0",
      apply_price: "0",
      apply_modifier: "0",
      multipleselection_apply: "0",
      disable_validation: "0",
      single_selection: "0",
    });
    this.props.onChange("comosetup", comosetup);
  }
  removeComboSet(removeIndex) {
    var comosetup = this.props.fields.comosetup;
    var newcomosetup = [];
    comosetup.map((item, index) => {
      if (removeIndex !== index) {
        newcomosetup.push(item);
      }
    });
    this.props.onChange("comosetup", newcomosetup);
  }
  handleChangeComboTxt(updIndex, updKey, event) {
    this.updateCombSet(updIndex, updKey, event.target.value);
  }
  handleChangeComboSelect(updIndex, updKey, value) {
    this.updateCombSet(updIndex, updKey, value);
  }
  handleChangeComboCheck(updIndex, updKey, event) {
    var value = event.target.checked === true ? "1" : "0";
    this.updateCombSet(updIndex, updKey, value);
  }
  updateCombSet(updIndex, updKey, updValue) {
    var como_setup = this.props.fields.comosetup;
    var updateComboSet = [];
    como_setup.map((item, index) => {
      if (index === updIndex) {
        var combo_name = item.combo_name;
        var products = item.products;
        var defaultproduct = item.defaultproduct;
        var combogroup = item.combogroup;
        var min_select = item.min_select;
        var max_select = item.max_select;
        var sequence = item.sequence;
        var piecescount = item.piecescount;
        var apply_price = item.apply_price;
        var apply_modifier = item.apply_modifier;
        var disable_validation = item.disable_validation;
        var multipleselection_apply = item.multipleselection_apply;
        var single_selection = item.single_selection;
        if (updKey === "combo_name") {
          combo_name = updValue;
        } else if (updKey === "products") {
          products = updValue;
        } else if (updKey === "defaultproduct") {
          defaultproduct = updValue;
        } else if (updKey === "combogroup") {
          combogroup = updValue;
        } else if (updKey === "min_select") {
          min_select = updValue;
        } else if (updKey === "max_select") {
          max_select = updValue;
        } else if (updKey === "sequence") {
          sequence = updValue;
        } else if (updKey === "piecescount") {
          piecescount = updValue;
        } else if (updKey === "apply_price") {
          apply_price = updValue;
        } else if (updKey === "apply_modifier") {
          apply_modifier = updValue;
        } else if (updKey === "multipleselection_apply") {
          multipleselection_apply = updValue;
        } else if (updKey === "disable_validation") {
          disable_validation = updValue;
        } else if (updKey === "single_selection") {
          single_selection = updValue;
        }

        updateComboSet.push({
          combo_name: combo_name,
          products: products,
          defaultproduct: defaultproduct,
          combogroup: combogroup,
          min_select: min_select,
          max_select: max_select,
          sequence: sequence,
          piecescount: piecescount,
          apply_price: apply_price,
          apply_modifier: apply_modifier,
          multipleselection_apply: multipleselection_apply,
          disable_validation: disable_validation,
          single_selection: single_selection,
        });
      } else {
        updateComboSet.push(item);
      }
    });
    this.props.onChange("comosetup", updateComboSet);
  }

  async uplaodGalleryImage() {
    this.props.sateValChange("btndisabled", true);
    var imagefile = document.querySelector("#gallery_images");
    const file = imagefile.files[0];
    var fileDetail = await uploadFile(file, "products", "client");
    if (fileDetail.status === "ok") {
      var gallery = this.props.fields.gallery;
      gallery.push(fileDetail.url);
      this.props.onChange("gal", gallery);
    }
    $("#gallery_images").val("");
    this.props.sateValChange("btndisabled", false);
  }
  async removeGalleryImage(fileNamme, removeIndex) {
    showLoader("galleryDetails_".removeIndex);
    var fileDetail = await removeFile(fileNamme, "client");
    if (fileDetail.status === "ok") {
      hideLoader("galleryDetails_".removeIndex);
      var gallery = this.props.fields.gallery;
      gallery.splice(removeIndex, 1);
      this.props.onChange("gallery", gallery);
    }
  }

  async uplaodFiles(imageType) {
    this.props.sateValChange("btndisabled", true);
    showLoader("ImageDetails");
    showLoader("ImageContainer");
    var imagefile = document.querySelector("#" + imageType);
    const file = imagefile.files[0];
    var fileDetail = await uploadFile(file, "products", "client");
    if (fileDetail.status === "ok") {
      this.props.onChange(imageType, fileDetail.url);
    }
    hideLoader("ImageContainer");
    hideLoader("ImageDetails");
    $("#" + imageType).val("");
    this.props.sateValChange("btndisabled", false);
  }
  async removeImage(fileNamme, imageType) {
    showLoader("ImageDetails", "class");
    var fileDetail = await removeFile(fileNamme, "client");
    if (fileDetail.status === "ok") {
      hideLoader("ImageDetails", "class");
      this.props.onChange(imageType, "");
    }
  }

  deleteAvailSlot(avilindex, avildayIndex, type) {
    var dayavailability = this.props.fields.dayavailability;

    var getAvil = dayavailability[avilindex];
    if (type === "daybased") {
      var updItem = [];
      getAvil.data[0].daybased.map((dayItem, dayIndex) => {
        if (dayIndex !== avildayIndex) {
          updItem.push(dayItem);
        }
      });
      getAvil.data[0].daybased = updItem;
    }
    if (type === "datebased") {
      var updItem = [];
      getAvil.data[1].datebased.map((dayItem, dayIndex) => {
        if (dayIndex !== avildayIndex) {
          updItem.push(dayItem);
        }
      });
      getAvil.data[1].datebased = updItem;
    }
    dayavailability[avilindex] = getAvil;
    this.props.onChange("dayavailability", dayavailability);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgOutlet,
      errMsgProductType,
      errMsgCategory,
      errMsgProName,
      errMsgsku,
      errMsgStatus,
      errMsgProPrice,
      errMsgProCost,
      errMsgProSpecialPrice,
      errMsgProStock = "";
    if ($validation.assign_outlet.error.reason !== undefined) {
      errMsgOutlet = $validation.assign_outlet.show && (
        <span className="error">{$validation.assign_outlet.error.reason}</span>
      );
    }
    if ($validation.product_type.error.reason !== undefined) {
      errMsgProductType = $validation.product_type.show && (
        <span className="error">{$validation.product_type.error.reason}</span>
      );
    }
    if ($validation.category.error.reason !== undefined) {
      errMsgCategory = $validation.category.show && (
        <span className="error">{$validation.category.error.reason}</span>
      );
    }
    if ($validation.product_name.error.reason !== undefined) {
      errMsgProName = $validation.product_name.show && (
        <span className="error">{$validation.product_name.error.reason}</span>
      );
    }
    if ($validation.sku.error.reason !== undefined) {
      errMsgsku = $validation.sku.show && (
        <span className="error">{$validation.sku.error.reason}</span>
      );
    }
    if ($validation.price.error.reason !== undefined) {
      errMsgProPrice = $validation.price.show && (
        <span className="error">{$validation.price.error.reason}</span>
      );
    }
    if ($validation.cost.error.reason !== undefined) {
      errMsgProCost = $validation.cost.show && (
        <span className="error">{$validation.cost.error.reason}</span>
      );
    }
    if ($validation.special_price.error.reason !== undefined) {
      errMsgProSpecialPrice = $validation.special_price.show && (
        <span className="error">{$validation.special_price.error.reason}</span>
      );
    }
    if ($validation.stock.error.reason !== undefined) {
      errMsgProStock = $validation.stock.show && (
        <span className="error">{$validation.stock.error.reason}</span>
      );
    }

    if ($validation.status.error.reason !== undefined) {
      errMsgStatus = $validation.status.show && (
        <span className="error">{$validation.status.error.reason}</span>
      );
    }
    var producttype = "";
    if (fields.product_type !== "") {
      if (fields.product_type.value === "2") {
        producttype = fields.product_type.value;
      }
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="row g-3">
          <div
            className="accordion mt-3 accordion-header-primary"
            id="accordionStyle1"
          >
            <div className="accordion-item active">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionStyle1-3"
                  aria-expanded="true"
                >
                  General Info
                </button>
              </h2>
              <div
                id="accordionStyle1-3"
                className="accordion-collapse collapse show  mt-3"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    {this.props.allowOutlet === "" && (
                      <div
                        className={
                          errMsgOutlet !== "" &&
                          errMsgOutlet !== false &&
                          errMsgOutlet !== undefined
                            ? "col-md-6 error-select error"
                            : "col-md-6"
                        }
                      >
                        <div className="form-floating form-floating-outline custm-select-box mb-4">
                          <Select
                            value={fields.assign_outlet}
                            onChange={this.handleSelectChange.bind(
                              this,
                              "assign_outlet"
                            )}
                            placeholder={"Select Outlet"}
                            options={this.props.outletList}
                            isClearable={true}
                          />
                          <label className="select-box-label">
                            Outlet<span className="error">*</span>
                          </label>
                          {errMsgOutlet}
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        errMsgProductType !== "" &&
                        errMsgProductType !== false &&
                        errMsgProductType !== undefined
                          ? "col-md-6 error-select error"
                          : "col-md-6"
                      }
                    >
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.product_type}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "product_type"
                          )}
                          placeholder={"Select Product Type"}
                          options={this.props.productTypeList}
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Product Type<span className="error">*</span>
                        </label>
                        {errMsgProductType}
                      </div>
                    </div>
                    <div
                      className={
                        errMsgCategory !== "" &&
                        errMsgCategory !== false &&
                        errMsgCategory !== undefined
                          ? "col-md-6 error-select error"
                          : "col-md-6"
                      }
                    >
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.category}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "category"
                          )}
                          placeholder={"Select Sub Category"}
                          options={
                            this.props.subcategoryList.length > 0
                              ? this.props.subcategoryList
                              : []
                          }
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Sub Category<span className="error">*</span>
                        </label>
                        {errMsgCategory}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.assign_availability}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "assign_availability"
                          )}
                          isMulti
                          placeholder="Select Availabilty"
                          options={this.props.availabiltyList.map((item) => {
                            return {
                              value: item.av_id,
                              label: item.av_name,
                            };
                          })}
                          isClearable={true}
                        />
                        <label className="select-box-label">Availabilty</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgProName !== "" &&
                            errMsgProName !== false &&
                            errMsgProName !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="product_name"
                          value={fields.product_name}
                          {...$field("product_name", (e) =>
                            onChange("product_name", e.target.value)
                          )}
                        />
                        <label htmlFor="product_name">
                          Product Name <span className="error">*</span>
                        </label>
                        {errMsgProName}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="alias_name"
                          value={fields.alias_name}
                          {...$field("alias_name", (e) =>
                            onChange("alias_name", e.target.value)
                          )}
                        />
                        <label htmlFor="alias_name">Alias Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgsku !== "" &&
                            errMsgsku !== false &&
                            errMsgsku !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="sku"
                          value={fields.sku}
                          {...$field("sku", (e) =>
                            onChange("sku", e.target.value)
                          )}
                        />
                        <label htmlFor="sku">
                          SKU <span className="error">*</span>
                        </label>
                        {errMsgsku}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="tag_info"
                          value={fields.tag_info}
                          {...$field("tag_info", (e) =>
                            onChange("tag_info", e.target.value)
                          )}
                        />
                        <label htmlFor="tag_info">Tag Info</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="min_quantity"
                          value={fields.min_quantity}
                          {...$field("min_quantity", (e) =>
                            onChange("min_quantity", e.target.value)
                          )}
                        />
                        <label htmlFor="min_quantity">Minimum Quantity</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="max_quantity"
                          value={fields.max_quantity}
                          {...$field("max_quantity", (e) =>
                            onChange("max_quantity", e.target.value)
                          )}
                        />
                        <label htmlFor="max_quantity">Maximum Quantity</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="sequence"
                          value={fields.sequence}
                          {...$field("sequence", (e) =>
                            onChange("sequence", e.target.value)
                          )}
                        />
                        <label htmlFor="sequence">Sequence</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="pos_id"
                          value={fields.pos_id}
                          {...$field("pos_id", (e) =>
                            onChange("pos_id", e.target.value)
                          )}
                        />
                        <label htmlFor="pos_id">POS ID</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <textarea
                          className="form-control h-px-75"
                          id="meta_keywords"
                          name="meta_keywords"
                          placeholder="Meta Keywords"
                          rows="3"
                          {...$field("meta_keywords", (e) =>
                            onChange("meta_keywords", e.target.value)
                          )}
                          value={fields.meta_keywords}
                        >
                          {fields.meta_keywords}
                        </textarea>
                        <label htmlFor="meta_keywords">Meta Keywords</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <textarea
                          className="form-control h-px-75"
                          id="meta_description"
                          name="meta_description"
                          placeholder="Meta Description"
                          value={fields.meta_description}
                          rows="3"
                          {...$field("meta_description", (e) =>
                            onChange("meta_description", e.target.value)
                          )}
                        ></textarea>
                        <label htmlFor="meta_description">
                          Meta Description
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="meta_title"
                          value={fields.meta_title}
                          {...$field("meta_title", (e) =>
                            onChange("meta_title", e.target.value)
                          )}
                        />
                        <label htmlFor="meta_title">Meta Title</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.product_disply_source}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "product_disply_source"
                          )}
                          isMulti
                          placeholder="Select Disply Source"
                          options={[
                            { label: "Web", value: "Web" },
                            { label: "Mobile App", value: "Mobile App" },
                            { label: "Callcenter", value: "Callcenter" },
                          ]}
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Disply Source
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label>Short Description</label>
                      <Editor
                        setContent={this.setContent}
                        data={fields.short_description}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Login Description</label>
                      <Editor
                        setContent={this.setLoginContent}
                        data={fields.long_description}
                      />
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.assign_tags}
                          isMulti
                          onChange={this.handleSelectChange.bind(
                            this,
                            "assign_tags"
                          )}
                          placeholder="Select Tags"
                          options={this.props.productTagList}
                          isClearable={true}
                        />
                        <label className="select-box-label">Tags</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.recommendation}
                          isMulti
                          onChange={this.handleSelectChange.bind(
                            this,
                            "recommendation"
                          )}
                          placeholder="Select Recommendation"
                          options={this.props.listProductSetup}
                          isClearable={true}
                        />
                        <label className="select-box-label">
                          Recommendation
                        </label>
                      </div>
                    </div>
                    <div
                      className={
                        errMsgStatus !== "" &&
                        errMsgStatus !== false &&
                        errMsgStatus !== undefined
                          ? "col-md-6 error-select error"
                          : "col-md-6"
                      }
                    >
                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                        <Select
                          value={fields.status}
                          onChange={this.handleSelectChange.bind(
                            this,
                            "status"
                          )}
                          placeholder="Select Status"
                          options={[
                            { value: "A", label: "Active" },
                            { value: "I", label: "In Active" },
                          ]}
                          isClearable={true}
                        />
                        <label className="select-box-label">Status</label>
                        {errMsgStatus}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionprice"
                  aria-expanded="false"
                >
                  Prices
                </button>
              </h2>

              <div
                id="accordionprice"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgProPrice !== "" &&
                            errMsgProPrice !== false &&
                            errMsgProPrice !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="price"
                          value={fields.price}
                          {...$field("price", (e) =>
                            onChange("price", e.target.value)
                          )}
                        />
                        <label htmlFor="price">
                          Product Price <span className="error">*</span>
                        </label>
                        {errMsgProPrice}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgProCost !== "" &&
                            errMsgProCost !== false &&
                            errMsgProCost !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="cost"
                          value={fields.cost}
                          {...$field("cost", (e) =>
                            onChange("cost", e.target.value)
                          )}
                        />
                        <label htmlFor="cost">Product Cost</label>
                        {errMsgProCost}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgProSpecialPrice !== "" &&
                            errMsgProSpecialPrice !== false &&
                            errMsgProSpecialPrice !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="special_price"
                          value={fields.special_price}
                          {...$field("special_price", (e) =>
                            onChange("special_price", e.target.value)
                          )}
                        />
                        <label htmlFor="special_price">Special Price</label>
                        {errMsgProSpecialPrice}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4 custm-date-box">
                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control"
                          selected={fields.special_price_from_date}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={this.handleChange.bind(
                            this,
                            "special_price_from_date"
                          )}
                        />
                        <label className="select-box-label">
                          Special Price From Date
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4 custm-date-box">
                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control"
                          selected={fields.special_price_to_date}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          onChange={this.handleChange.bind(
                            this,
                            "special_price_to_date"
                          )}
                        />
                        <label className="select-box-label">
                          Special Price To Date
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionstock"
                  aria-expanded="false"
                >
                  Stock
                </button>
              </h2>

              <div
                id="accordionstock"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className={
                            errMsgProStock !== "" &&
                            errMsgProStock !== false &&
                            errMsgProStock !== undefined
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="stock"
                          value={fields.stock}
                          {...$field("stock", (e) =>
                            onChange("stock", e.target.value)
                          )}
                        />
                        <label htmlFor="stock">
                          Available Stock <span className="error">*</span>
                        </label>
                        {errMsgProStock}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="stock_min"
                          value={fields.stock_min}
                          {...$field("stock_min", (e) =>
                            onChange("stock_min", e.target.value)
                          )}
                        />
                        <label htmlFor="stock_min">Minimum Stock</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-check form-check-inline mt-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="low_stock_alert"
                          checked={
                            fields.low_stock_alert === "1" ? true : false
                          }
                          onChange={this.handleChangeCheck.bind(
                            this,
                            "low_stock_alert"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="low_stock_alert"
                        >
                          Low Stock Alert Mail
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {fields.product_type === "5" && (
              <div className="accordion-item ">
                <h2 className="accordion-header">
                  <button
                    type="button"
                    className="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionvoucher"
                    aria-expanded="false"
                  >
                    Voucher Details
                  </button>
                </h2>

                <div
                  id="accordionvoucher"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionStyle1"
                >
                  <div className="accordion-body">
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline custm-select-box mb-4">
                          <Select
                            value={fields.voucher_type}
                            onChange={this.handleSelectChange.bind(
                              this,
                              "voucher_type"
                            )}
                            placeholder="Select Voucher Type"
                            options={[
                              { value: "c", label: "Cash Voucher" },
                              { value: "f", label: "Food Voucher" },
                            ]}
                            isClearable={true}
                          />
                          <label className="select-box-label">
                            Voucher Type
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline mb-4 custm-date-box">
                          <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control"
                            selected={fields.voucher_expity_date}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            onChange={this.handleChange.bind(
                              this,
                              "voucher_expity_date"
                            )}
                          />
                          <label className="select-box-label">
                            Voucher Expiry Date
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="text"
                            className="form-control"
                            name="voucher_number_date"
                            value={fields.voucher_number_date}
                            {...$field("voucher_number_date", (e) =>
                              onChange("voucher_number_date", e.target.value)
                            )}
                          />
                          <label htmlFor="voucher_number_date">
                            Number Of Dates <span className="error">*</span>
                          </label>
                        </div>
                      </div>
                      {fields.voucher_type !== "" && (
                        <>
                          {fields.voucher_type.value === "c" && (
                            <div className="col-md-6">
                              <div className="form-floating form-floating-outline mb-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="product_voucher_points"
                                  value={fields.product_voucher_points}
                                  {...$field("product_voucher_points", (e) =>
                                    onChange(
                                      "product_voucher_points",
                                      e.target.value
                                    )
                                  )}
                                  id="product_voucher_points"
                                />
                                <label htmlFor="product_voucher_points">
                                  Voucher Points{" "}
                                  <span className="error">*</span>
                                </label>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="text"
                            className="form-control"
                            name="voucher_increase_qty"
                            value={fields.voucher_increase_qty}
                            {...$field("voucher_increase_qty", (e) =>
                              onChange("voucher_increase_qty", e.target.value)
                            )}
                          />
                          <label htmlFor="voucher_increase_qty">
                            Number Of Item
                          </label>
                        </div>
                      </div>

                      {fields.voucher_type !== "" && (
                        <>
                          {fields.voucher_type.value === "f" && (
                            <>
                              <div className="col-md-6">
                                <div className="form-floating form-floating-outline custm-select-box mb-4">
                                  <Select
                                    value={fields.reedmed_availability}
                                    onChange={this.handleSelectChange.bind(
                                      this,
                                      "reedmed_availability"
                                    )}
                                    isMulti
                                    placeholder="Select Food Voucher Redeem Availability"
                                    options={this.props.availabiltyList.map(
                                      (item) => {
                                        return {
                                          value: item.av_id,
                                          label: item.av_name,
                                        };
                                      }
                                    )}
                                    isClearable={true}
                                  />
                                  <label className="select-box-label">
                                    Food Voucher Redeem Availability
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating form-floating-outline custm-select-box mb-4">
                                  <Select
                                    value={fields.voucher_food_option}
                                    onChange={this.handleSelectChange.bind(
                                      this,
                                      "voucher_food_option"
                                    )}
                                    placeholder="Select Food Voucher Option"
                                    options={[
                                      { value: "1", label: "Normal" },
                                      { value: "2", label: "Discount" },
                                    ]}
                                    isClearable={true}
                                  />
                                  <label className="select-box-label">
                                    Food Voucher Option
                                  </label>
                                </div>
                              </div>
                              {fields.voucher_food_option !== "" && (
                                <>
                                  {fields.voucher_food_option.value === "2" && (
                                    <>
                                      <div className="col-md-6">
                                        <div className="form-floating form-floating-outline custm-select-box mb-4">
                                          <Select
                                            value={
                                              fields.voucher_food_discount_type
                                            }
                                            onChange={this.handleSelectChange.bind(
                                              this,
                                              "voucher_food_discount_type"
                                            )}
                                            placeholder="Select Food Voucher Discount Type"
                                            options={[
                                              { value: "1", label: "Fixed" },
                                              {
                                                value: "2",
                                                label: "Percentage",
                                              },
                                            ]}
                                            isClearable={true}
                                          />
                                          <label className="select-box-label">
                                            Food Voucher Discount Type
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-floating form-floating-outline mb-4">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="food_voucher_disc_val"
                                            value={fields.food_voucher_disc_val}
                                            {...$field(
                                              "food_voucher_disc_val",
                                              (e) =>
                                                onChange(
                                                  "food_voucher_disc_val",
                                                  e.target.value
                                                )
                                            )}
                                          />
                                          <label htmlFor="food_voucher_disc_val">
                                            Food Voucher Discount Value
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                              <div className="col-md-6">
                                <div className="form-floating form-floating-outline custm-select-box mb-4">
                                  <Select
                                    value={fields.voucher_free_products}
                                    onChange={this.handleSelectChange.bind(
                                      this,
                                      "voucher_free_products"
                                    )}
                                    placeholder={
                                      "Select Free Product / Voucher"
                                    }
                                    options={this.props.simpleProducts}
                                    isClearable={true}
                                  />
                                  <label className="select-box-label">
                                    Free Product / Voucher
                                  </label>
                                </div>
                              </div>

                              {fields.voucher_food_option !== "" && (
                                <>
                                  {fields.voucher_food_option.value === "2" && (
                                    <div className="col-md-12">
                                      <div className="form-floating form-floating-outline custm-select-box mb-4">
                                        <Select
                                          value={
                                            fields.voucher_included_products
                                          }
                                          onChange={this.handleSelectChange.bind(
                                            this,
                                            "voucher_included_products"
                                          )}
                                          isMulti
                                          placeholder={
                                            "Select Included Products"
                                          }
                                          options={this.props.simpleProducts}
                                          isClearable={true}
                                        />
                                        <label className="select-box-label">
                                          Included Products
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordiongallery"
                  aria-expanded="false"
                >
                  Gallery
                </button>
              </h2>

              <div
                id="accordiongallery"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <div className="mb-3" id="ImageContainer">
                          <label htmlFor="formFile" className="form-label">
                            Thumbnail Image
                          </label>
                          <br />
                          <span>Recommended Size 250 X 120 PX</span>
                          <input
                            className="form-control"
                            type="file"
                            id="thumbnail"
                            onChange={(event) => {
                              this.uplaodFiles("thumbnail", event);
                              return false;
                            }}
                          />
                        </div>
                      </div>
                      {fields.thumbnail !== "" && (
                        <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
                          <div className="dz-details" id="ImageDetails">
                            <div className="dz-thumbnail">
                              <img alt="" src={fields.thumbnail} />
                            </div>
                          </div>
                          <a
                            className="dz-remove"
                            href={() => false}
                            onClick={this.removeImage.bind(
                              this,
                              fields.thumbnail,
                              "thumbnail"
                            )}
                          >
                            Remove file
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12">
                      <div className="card mb-4">
                        <h5 className="card-header">Gallery Images</h5>
                        <div className="card-body">
                          <div className="dz-message needsclick">
                            Drop files here or click to upload
                            <span className="note needsclick">
                              (This is just a demo dropzone. Selected files are{" "}
                              <strong>not</strong> actually uploaded.)
                            </span>
                          </div>
                          <div className="fallback">
                            <input
                              name="file"
                              type="file"
                              id="gallery_images"
                              onChange={(event) => {
                                this.uplaodGalleryImage(event);
                                return false;
                              }}
                            />
                          </div>
                          {fields.gallery.length > 0 &&
                            fields.gallery.map((item, index) => {
                              return (
                                <div
                                  className="dz-preview dz-processing dz-image-preview dz-success dz-complete"
                                  key={index}
                                  id={`galleryDetails_${index}`}
                                >
                                  <div className="dz-details">
                                    <div className="dz-thumbnail">
                                      <img alt="" src={item} />
                                    </div>
                                  </div>
                                  <a
                                    className="dz-remove"
                                    href={() => false}
                                    onClick={this.removeGalleryImage.bind(
                                      this,
                                      item,
                                      index
                                    )}
                                  >
                                    Remove file
                                  </a>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item ">
              <h2 className="accordion-header">
                <button
                  type="button"
                  className="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordiontimeavailability"
                  aria-expanded="false"
                >
                  Time Availability
                </button>
              </h2>

              <div
                id="accordiontimeavailability"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionStyle1"
              >
                <div className="accordion-body">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating form-floating-outline mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="availability_errorinfo"
                          value={fields.availability_errorinfo}
                          {...$field("availability_errorinfo", (e) =>
                            onChange("availability_errorinfo", e.target.value)
                          )}
                        />
                        <label htmlFor="availability_errorinfo">
                          Product Availability Errorinfo
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="pro_advancedaytime_availability_div">
                        {fields.dayavailability.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="pro_current_daydatewise_title">
                                {item.title}
                              </div>

                              <div className="col-md-12">
                                <div className="pro_daydatewise_availability">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="pro_daywise_availability">
                                        <div className="pro_daywise_availability_main">
                                          <div className="pro_current_daywise_title">
                                            Day based availability
                                            <span
                                              className="mdi mdi-plus-box text-end"
                                              onClick={this.addNew.bind(
                                                this,
                                                index,
                                                "daybased"
                                              )}
                                            ></span>
                                          </div>
                                          {item.data[0].daybased.map(
                                            (dayItem, dayIndex) => {
                                              var selectedday =
                                                dayItem.day !== "" &&
                                                dayItem.day !== null
                                                  ? dayItem.day.split(",")
                                                  : [];
                                              return (
                                                <div
                                                  className="daywiseitem_text_div mb-2"
                                                  key={dayIndex}
                                                >
                                                  {item.data[0].daybased
                                                    .length > 1 && (
                                                    <div className="text-end">
                                                      {" "}
                                                      <span
                                                        className="mdi mdi-trash-can-outline"
                                                        onClick={this.deleteAvailSlot.bind(
                                                          this,
                                                          index,
                                                          dayIndex,
                                                          "daybased"
                                                        )}
                                                      ></span>
                                                    </div>
                                                  )}
                                                  <div className="form-group daywiseitem_form_group">
                                                    {dayList.map(
                                                      (day, dayListIndex) => {
                                                        return (
                                                          <div
                                                            className="form-check form-check-inline mt-3"
                                                            key={dayListIndex}
                                                          >
                                                            <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              id={
                                                                "day_" +
                                                                dayIndex +
                                                                "_" +
                                                                dayListIndex +
                                                                "_" +
                                                                day
                                                              }
                                                              value={day.toLowerCase()}
                                                              checked={
                                                                selectedday.indexOf(
                                                                  day.toLowerCase()
                                                                ) >= 0
                                                                  ? true
                                                                  : false
                                                              }
                                                              onChange={this.handleChangeCheckBox.bind(
                                                                this,
                                                                item.type,
                                                                dayIndex,
                                                                "daybased",
                                                                "day"
                                                              )}
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor={
                                                                "day_" +
                                                                dayIndex +
                                                                "_" +
                                                                dayListIndex +
                                                                "_" +
                                                                day
                                                              }
                                                            >
                                                              {day}
                                                            </label>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-md-3">
                                                      <div className="form-floating-outline mt-2 custm-date-box">
                                                        <DatePicker
                                                          className="form-control"
                                                          showTimeSelect
                                                          showTimeSelectOnly
                                                          timeIntervals={15}
                                                          selected={
                                                            dayItem.from !==
                                                              "" &&
                                                            dayItem.from !==
                                                              null
                                                              ? new Date(
                                                                  dayItem.from
                                                                )
                                                              : ""
                                                          }
                                                          timeCaption="Time"
                                                          dateFormat="h:mm aa"
                                                          onChange={this.handleChangeAvailDate.bind(
                                                            this,
                                                            item.type,
                                                            dayIndex,
                                                            "daybased",
                                                            "fromtime"
                                                          )}
                                                        />
                                                        <label className="select-box-label">
                                                          From
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <div className="form-floating-outline mt-2 custm-date-box">
                                                        <DatePicker
                                                          className="form-control"
                                                          showTimeSelect
                                                          showTimeSelectOnly
                                                          timeIntervals={15}
                                                          timeCaption="Time"
                                                          dateFormat="h:mm aa"
                                                          selected={
                                                            dayItem.to !== "" &&
                                                            dayItem.to !== null
                                                              ? new Date(
                                                                  dayItem.to
                                                                )
                                                              : ""
                                                          }
                                                          onChange={this.handleChangeAvailDate.bind(
                                                            this,
                                                            item.type,
                                                            dayIndex,
                                                            "daybased",
                                                            "totime"
                                                          )}
                                                        />
                                                        <label className="select-box-label">
                                                          To
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <div className="form-floating-outline mt-2">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          value={dayItem.stock}
                                                          onChange={this.handleChangeText.bind(
                                                            this,
                                                            item.type,
                                                            dayIndex,
                                                            "daybased",
                                                            "stock"
                                                          )}
                                                          placeholder="Stock"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <div className="form-check form-check-inline mt-3">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={
                                                            "day_" +
                                                            index +
                                                            "_" +
                                                            dayIndex +
                                                            "_validate"
                                                          }
                                                          value="Yes"
                                                          checked={
                                                            dayItem.validate ===
                                                            "Yes"
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={this.handleChangeCheckBox.bind(
                                                            this,
                                                            item.type,
                                                            dayIndex,
                                                            "daybased",
                                                            "validate"
                                                          )}
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor={
                                                            "day_" +
                                                            index +
                                                            "_" +
                                                            dayIndex +
                                                            "_validate"
                                                          }
                                                        >
                                                          Validate
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="pro_datewise_availability">
                                        <div className="pro_datewise_availability_main">
                                          <input
                                            type="hidden"
                                            name="datewise_avbl_count"
                                            id="datewise_avbl_count"
                                            className="datewise_avbl_count"
                                            value="0"
                                          />
                                          <div className="pro_current_datewise_title">
                                            Date based availability
                                            <span
                                              className="mdi mdi-plus-box text-end"
                                              onClick={this.addNew.bind(
                                                this,
                                                index,
                                                "datebased"
                                              )}
                                            ></span>
                                          </div>
                                          {item.data[1].datebased.map(
                                            (dateItem, dateIndex) => {
                                              return (
                                                <div
                                                  className="daywiseitem_text_div mb-2"
                                                  key={dateIndex}
                                                >
                                                  {item.data[1].datebased
                                                    .length > 1 && (
                                                    <div className="text-end">
                                                      {" "}
                                                      <span
                                                        className="mdi mdi-trash-can-outline "
                                                        onClick={this.deleteAvailSlot.bind(
                                                          this,
                                                          index,
                                                          dateIndex,
                                                          "datebased"
                                                        )}
                                                      ></span>
                                                    </div>
                                                  )}
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <div className="form-floating-outline mt-3 custm-date-box">
                                                        <DatePicker
                                                          peekNextMonth
                                                          showMonthDropdown
                                                          showYearDropdown
                                                          showTimeSelect
                                                          dropdownMode="select"
                                                          className="form-control"
                                                          minDate={new Date()}
                                                          dateFormat="d-MM-yyyy h:mm aa"
                                                          selected={
                                                            dateItem.from !==
                                                              "" &&
                                                            dateItem.from !==
                                                              null
                                                              ? new Date(
                                                                  dateItem.from
                                                                )
                                                              : ""
                                                          }
                                                          onChange={this.handleChangeAvailDate.bind(
                                                            this,
                                                            item.type,
                                                            dateIndex,
                                                            "datebased",
                                                            "fromdate"
                                                          )}
                                                        />
                                                        <label className="select-box-label">
                                                          From
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                      <div className="form-floating-outline mt-3 custm-date-box">
                                                        <DatePicker
                                                          peekNextMonth
                                                          showMonthDropdown
                                                          showYearDropdown
                                                          showTimeSelect
                                                          dropdownMode="select"
                                                          className="form-control"
                                                          dateFormat="d-MM-yyyy h:mm aa"
                                                          minDate={
                                                            dateItem.from !==
                                                              "" &&
                                                            dateItem.from !==
                                                              null
                                                              ? new Date(
                                                                  dateItem.from
                                                                )
                                                              : new Date()
                                                          }
                                                          selected={
                                                            dateItem.to !==
                                                              "" &&
                                                            dateItem.to !== null
                                                              ? new Date(
                                                                  dateItem.to
                                                                )
                                                              : ""
                                                          }
                                                          onChange={this.handleChangeAvailDate.bind(
                                                            this,
                                                            item.type,
                                                            dateIndex,
                                                            "datebased",
                                                            "todate"
                                                          )}
                                                        />
                                                        <label className="select-box-label">
                                                          To
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                      <div className="form-floating-outline mt-3">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          value={dateItem.stock}
                                                          onChange={this.handleChangeText.bind(
                                                            this,
                                                            item.type,
                                                            dateIndex,
                                                            "datebased",
                                                            "stock"
                                                          )}
                                                          placeholder="Stock"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                      <div className="form-check form-check-inline mt-4">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={
                                                            "date_" +
                                                            index +
                                                            "_" +
                                                            dateIndex +
                                                            "_validate"
                                                          }
                                                          value="Yes"
                                                          checked={
                                                            dateItem.validate ===
                                                            "Yes"
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={this.handleChangeCheckBox.bind(
                                                            this,
                                                            item.type,
                                                            dateIndex,
                                                            "datebased",
                                                            "validate"
                                                          )}
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor={
                                                            "date_" +
                                                            index +
                                                            "_" +
                                                            dateIndex +
                                                            "_validate"
                                                          }
                                                        >
                                                          Validate
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {producttype === "2" && (
              <div className="accordion-item ">
                <h2 className="accordion-header">
                  <button
                    type="button"
                    className="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordioncombo-1"
                    aria-expanded="false"
                  >
                    Combo Setup
                  </button>
                </h2>

                <div
                  id="accordioncombo-1"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionStyle1"
                >
                  <div className="accordion-body">
                    <div className="col-md-12 text-end mb-3">
                      <a
                        href={() => false}
                        onClick={this.addNewCombo.bind(this)}
                      >
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect"
                        >
                          Add New
                        </button>
                      </a>
                    </div>
                    <div className="form-check form-check-inline mt-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={"apply_minmax_select"}
                        defaultChecked={
                          fields.apply_minmax_select === "1" ? true : false
                        }
                        onChange={this.handleChangeCheck.bind(
                          this,
                          "apply_minmax_select"
                        )}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"apply_minmax_select"}
                      >
                        Apply Minimum, Maximum Setup
                      </label>
                    </div>

                    {fields.comosetup.length > 0 &&
                      fields.comosetup.map((item, index) => {
                        return (
                          <div
                            className="card mb-4 border-2 border-primary mb-3"
                            key={index}
                          >
                            <div className="card-body">
                              {fields.comosetup.length > 1 && (
                                <div className="text-end">
                                  <a
                                    href={() => false}
                                    onClick={this.removeComboSet.bind(
                                      this,
                                      index
                                    )}
                                  >
                                    <span className="mdi mdi-trash-can-outline"></span>
                                  </a>
                                </div>
                              )}
                              <h4 className="card-title">
                                {item.combo_name !== ""
                                  ? item.combo_name
                                  : "Combo Set " + (parseInt(index) + 1)}
                              </h4>
                              <div className="row g-3">
                                <div className="col-md-6">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <input
                                      type="text"
                                      value={item.combo_name}
                                      className="form-control"
                                      onChange={this.handleChangeComboTxt.bind(
                                        this,
                                        index,
                                        "combo_name"
                                      )}
                                    />
                                    <label htmlFor="price">
                                      Product Set Name{" "}
                                      <span className="error">*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-floating form-floating-outline custm-select-box mb-4">
                                    <Select
                                      value={item.products}
                                      onChange={this.handleChangeComboSelect.bind(
                                        this,
                                        index,
                                        "products"
                                      )}
                                      isMulti
                                      placeholder={"Combo Products"}
                                      options={this.props.simpleProducts}
                                      isClearable={true}
                                    />
                                    <label className="select-box-label">
                                      Combo Products
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-floating form-floating-outline custm-select-box mb-4">
                                    <Select
                                      value={item.combogroup}
                                      onChange={this.handleChangeComboSelect.bind(
                                        this,
                                        index,
                                        "combogroup"
                                      )}
                                      isMulti
                                      placeholder={
                                        "Select Combo Product Groups"
                                      }
                                      options={this.props.prdouctGroupList}
                                      isClearable={true}
                                    />
                                    <label className="select-box-label">
                                      Combo Product Groups
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating form-floating-outline custm-select-box mb-4">
                                    <Select
                                      value={item.defaultproduct}
                                      onChange={this.handleChangeComboSelect.bind(
                                        this,
                                        index,
                                        "defaultproduct"
                                      )}
                                      placeholder={"Select Default Products"}
                                      options={this.props.simpleProducts}
                                      isClearable={true}
                                    />
                                    <label className="select-box-label">
                                      Default Products
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={item.piecescount}
                                      onChange={this.handleChangeComboTxt.bind(
                                        this,
                                        index,
                                        "piecescount"
                                      )}
                                    />
                                    <label htmlFor="piecescount">
                                      Pieces Count
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="min_select"
                                      value={item.min_select}
                                      onChange={this.handleChangeComboTxt.bind(
                                        this,
                                        index,
                                        "min_select"
                                      )}
                                    />
                                    <label htmlFor="min_select">
                                      Minimum Select
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="max_select"
                                      value={item.max_select}
                                      onChange={this.handleChangeComboTxt.bind(
                                        this,
                                        index,
                                        "max_select"
                                      )}
                                    />
                                    <label htmlFor="max_select">
                                      Maximum Select
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sequence"
                                      value={item.sequence}
                                      onChange={this.handleChangeComboTxt.bind(
                                        this,
                                        index,
                                        "sequence"
                                      )}
                                    />
                                    <label htmlFor="sequence">Sort Order</label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-check form-check-inline mt-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={"apply_price_" + index}
                                      defaultChecked={
                                        item.apply_modifier === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={this.handleChangeComboCheck.bind(
                                        this,
                                        index,
                                        "apply_price"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"apply_price_" + index}
                                    >
                                      Apply Price
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mt-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={"apply_modifier_" + index}
                                      defaultChecked={
                                        item.apply_modifier === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={this.handleChangeComboCheck.bind(
                                        this,
                                        index,
                                        "apply_modifier"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"apply_modifier_" + index}
                                    >
                                      Apply Modifier
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mt-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={"multipleselection_apply" + index}
                                      defaultChecked={
                                        item.multipleselection_apply === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={this.handleChangeComboCheck.bind(
                                        this,
                                        index,
                                        "multipleselection_apply"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={
                                        "multipleselection_apply" + index
                                      }
                                    >
                                      Multiple Selections
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mt-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={"apply_price_" + index}
                                      defaultChecked={
                                        item.disable_validation === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={this.handleChangeComboCheck.bind(
                                        this,
                                        index,
                                        "disable_validation"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"disable_validation_" + index}
                                    >
                                      Turn Off Min Validation
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mt-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={"single_selection_" + index}
                                      defaultChecked={
                                        item.single_selection === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={this.handleChangeComboCheck.bind(
                                        this,
                                        index,
                                        "single_selection"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"single_selection_" + index}
                                    >
                                      With Image
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="col-md-12 text-end mb-3">
                      <a
                        href={() => false}
                        onClick={this.addNewCombo.bind(this)}
                      >
                        <button
                          type="button"
                          className="btn btn-outline-primary waves-effect"
                        >
                          Add New
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="pt-1 pb-4 pr-2 text-end"
            style={{ paddingRight: "20px" }}
          >
            <button
              type="button"
              disabled={this.props.btndisabled}
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                if (!this.props.btndisabled) {
                  this.props.$submit(onValid, onInvalid);
                }
              }}
            >
              Submit
            </button>
            <Link to={modulePath}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
