import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import Autocomplete from "../../Layout/Autocomplete";
import { apiUrl, clientheaderconfig, siteMainURL } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  isEmpty,
  isSingleSelect,
  isNumber,
  isValidPrice,
  getReferenceID,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
var module = "clientpanel/outletzone/";
class Zoneform extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/clientpanel/zone/edit/:EditID") {
      editID = this.props.match.params.EditID;
    }
    this.state = {
      editID: editID,
      pageloading: false,
      postdata: {
        zone_name: "",
        site_location_id: "",
        zone_availability: [],
        zone_postal_code: "",
        zone_address_line1: "",
        zone_min_amount: "",
        /* zone_additional_charge: "", */
        zone_free_delivery: "",
        status: "",
        action: "add",
      },
      loading: true,
      availabiltyList: [],
      formpost: [],
      companyDetail: [],
      siteLocation: [],
      region_typemap: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.loadSiteLocation();
    this.loadAvailabilty();

    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + CompanyID() + "&zone_id=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
          this.props.history.push("/clientpanel/zone");
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          var availability = result.zone_availability;
          var availabilityList = [];
          if (availability.length > 0) {
            availability.map((item) => {
              availabilityList.push({
                value: item.oza_availability_id,
                label: item.av_name,
              });
            });
          }
          var zone_status = result.zone_status == "A" ? "Active" : "In Active";
          var status =
            result.zone_status !== "" && result.zone_status !== null
              ? {
                  label: zone_status,
                  value: result.zone_status,
                }
              : "";
          var clientupdatedata = {
            zone_name: result.zone_name,
            site_location_id: result.site_location,
            zone_availability: availabilityList,
            zone_postal_code: result.zone_postal_code,
            zone_address_line1: result.zone_address_line1,
            zone_min_amount: result.zone_min_amount,
            /* zone_additional_charge: result.zone_additional_charge, */
            zone_free_delivery: result.zone_free_delivery,
            status: status,
            action: "edit",
          };
          this.setState({ postdata: clientupdatedata, pageloading: false });
        } else {
          this.props.history.push("/clientpanel/zone");
          showAlert("Error", "Invalid Zone", "error");
        }
      });
    }
  }
  loadSiteLocation() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/sitelocation/dropdownlist?company_id=" +
      CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ siteLocation: res.data.result });
      }
    });
  }
  loadAvailabilty() {
    var urlShringTxt = apiUrl + "company/settings/availabilty_list";

    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "success") {
        this.setState({ availabiltyList: res.data.result });
      }
    });
  }

  sateValChange = (field, value) => {};

  handleChange(checked, name) {
    this.setState({ checked });
  }

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { postdata: { [field]: { $set: value } } })
    );
  };

  handleSubmit = () => {
    var getIframeVal = $("iframe[name=zonemap]")
      .contents()
      .find("#iframeInputBox")
      .val();
    /*  console.log(
      $("iframe[name=zonemap]").contents().prevObject[0].html(),
      "aaaaaaa"
    ); */
    /*  console.log($("#zonemap").contents().prevObject.context);
    var region_typemap =
      $("#zonemap").contents().prevObject.context.all.region_typemap;
    console.log(region_typemap); */
    /* var splitID = (new String(id)) */
    /*  this.setState({ region_typemap: region_typemap }); */

    //return false;
    /* const iframe = document.getElementById("zonemap");
    const iWindow = iframe.contentWindow;
    const iDocument = iWindow.document; */

    // accessing the element
    /* const element = iDocument.getElementsByTagName("region_points")[0]; */
    //element.style.color = "green";

    /*   let textArea = iframeDoc.contentWindow.document.getElementById("some-textarea")[0]
     */
    /* .find("#region_pointsmap")
      .val() */
    //var name = $("#zonemap").contents().find("#region_points").val();
    /*  console.log(element, "zonemapzonemap");
    return false; */
    /*  var getIframeVal = $("iframe[name=zonemap]")
      .contents()
      .find("#region_pointsmap")
      .val();
    console.log(getIframeVal, "zonemapzonemap");
    return false; */
    showLoader("submit_frm", "class");
    var postData = this.state.postdata;

    var zone_availability = [];
    if (postData.zone_availability.length > 0) {
      postData.zone_availability.map((item) => {
        zone_availability.push(item.value);
      });
    }

    var postObject = {
      zone_name: postData.zone_name,
      zone_availability:
        zone_availability.length > 0 ? zone_availability.join(",") : "",
      zone_postal_code: postData.zone_postal_code,
      zone_address_line1: postData.zone_address_line1,
      zone_min_amount: postData.zone_min_amount,
      /* zone_additional_charge: postData.zone_additional_charge, */
      zone_free_delivery: postData.zone_free_delivery,
      status:
        Object.keys(postData.status).length > 0 ? postData.status.value : "A",
      site_location_id:
        Object.keys(postData.site_location_id).length > 0
          ? postData.site_location_id.value
          : "A",
      loginID: userID(),
      company_admin_id: clientID(),
      company_id: CompanyID(),
      outlet: "173",
      map_random_id: getReferenceID(),
      action: postData.action,
    };

    var post_url = module + "add";
    if (postData.action === "edit" && this.state.editID !== "") {
      postObject["edit_id"] = this.state.editID;
      post_url = module + "update";
    }
    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"zone"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"} Zone
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={"/clientpanel/zone"}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="kkkkkkkk">{this.state.region_typemap}</div>
                <Form
                  {...this.props}
                  fields={this.state.postdata}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  error_msg={this.state.error_msg}
                  availabiltyList={this.state.availabiltyList}
                  siteLocation={this.state.siteLocation}
                  editID={this.state.editID}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Zoneform);

function validationConfig(props) {
  const {
    zone_name,
    site_location_id,
    zone_availability,
    zone_postal_code,
    zone_address_line1,
    zone_min_amount,
    /* zone_additional_charge, */
    zone_free_delivery,
    status,
  } = props.fields;

  return {
    fields: [
      "zone_name",
      "site_location_id",
      "zone_availability",
      "zone_postal_code",
      "zone_address_line1",
      "zone_min_amount",
      /* "zone_additional_charge", */
      "zone_free_delivery",
      "status",
    ],

    validations: {
      zone_name: [[isEmpty, zone_name]],
      zone_availability: [[isSingleSelect, zone_availability]],
      site_location_id: [[isSingleSelect, site_location_id]],
      zone_postal_code: [
        [isEmpty, zone_postal_code],
        [isNumber, zone_postal_code],
      ],
      zone_address_line1: [[isEmpty, zone_address_line1]],
      zone_min_amount: [[isValidPrice, zone_min_amount]],
      /* zone_additional_charge: [[isValidPrice, zone_additional_charge]], */
      zone_free_delivery: [[isValidPrice, zone_free_delivery]],
      status: [[isSingleSelect, status]],
    },
  };
}

class Form extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }

  sateValChange = (field, value) => {
    if (field === "address") {
      if (value !== "") {
        var currentThis = this;
        var address = $("#pac-input").val();
        var addressdetails = value.split("~~");
        currentThis.props.onChange("zone_address_line1", address);
        setTimeout(function () {
          currentThis.props.onChange("zone_postal_code", addressdetails[4]);
        }, 100);
      }
    }
  };

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgZoneName,
      errMsgAvail,
      errMsgSiteLoc,
      errMsgPostalCode,
      errMssAddress,
      errmMsgMinAmt,
      errmMsgDelivery,
      errmMsgAddDelivery,
      errmMsgFreeDelivery,
      errMsgStatus = "";
    if ($validation.zone_name.error.reason !== undefined) {
      errMsgZoneName = $validation.zone_name.show && (
        <span className="error">{$validation.zone_name.error.reason}</span>
      );
    }
    if ($validation.site_location_id.error.reason !== undefined) {
      errMsgSiteLoc = $validation.site_location_id.show && (
        <span className="error">
          {$validation.site_location_id.error.reason}
        </span>
      );
    }
    if ($validation.zone_availability.error.reason !== undefined) {
      errMsgAvail = $validation.zone_availability.show && (
        <span className="error">
          {$validation.zone_availability.error.reason}
        </span>
      );
    }
    if ($validation.zone_postal_code.error.reason !== undefined) {
      errMsgPostalCode = $validation.zone_postal_code.show && (
        <span className="error">
          {$validation.zone_postal_code.error.reason}
        </span>
      );
    }
    if ($validation.zone_address_line1.error.reason !== undefined) {
      errMssAddress = $validation.zone_address_line1.show && (
        <span className="error">
          {$validation.zone_address_line1.error.reason}
        </span>
      );
    }
    if ($validation.zone_min_amount.error.reason !== undefined) {
      errmMsgMinAmt = $validation.zone_min_amount.show && (
        <span className="error">
          {$validation.zone_min_amount.error.reason}
        </span>
      );
    }
    /*  if ($validation.zone_additional_charge.error.reason !== undefined) {
      errmMsgDelivery = $validation.zone_additional_charge.show && (
        <span className="error">
          {$validation.zone_additional_charge.error.reason}
        </span>
      );
    } */
    if ($validation.zone_free_delivery.error.reason !== undefined) {
      errmMsgFreeDelivery = $validation.zone_free_delivery.show && (
        <span className="error">
          {$validation.zone_free_delivery.error.reason}
        </span>
      );
    }
    if ($validation.status.error.reason !== undefined) {
      errMsgStatus = $validation.status.show && (
        <span className="error">{$validation.status.error.reason}</span>
      );
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="card-body row g-3">
          <div
            className={
              errMsgSiteLoc !== "" &&
              errMsgSiteLoc !== false &&
              errMsgSiteLoc !== undefined
                ? "col-md-6 error-select error"
                : "col-md-6"
            }
          >
            <div className="form-floating form-floating-outline custm-select-box">
              <Select
                value={fields.site_location_id}
                onChange={this.handleSelectChange.bind(
                  this,
                  "site_location_id"
                )}
                placeholder="Select Site Location"
                options={this.props.siteLocation}
              />
              <label className="select-box-label">
                Site Location<span className="error">*</span>
              </label>
              {errMsgSiteLoc}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errMsgZoneName !== "" &&
                  errMsgZoneName !== false &&
                  errMsgZoneName !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="zone_name"
                value={fields.zone_name}
                {...$field("zone_name", (e) =>
                  onChange("zone_name", e.target.value)
                )}
              />
              <label htmlFor="zone_name">
                Zone Name <span className="error">*</span>
              </label>
              {errMsgZoneName}
            </div>
          </div>
          <div
            className={
              errMsgAvail !== "" &&
              errMsgAvail !== false &&
              errMsgAvail !== undefined
                ? "col-md-6 error-select error"
                : "col-md-6"
            }
          >
            <div className="form-floating form-floating-outline custm-select-box">
              <Select
                value={fields.zone_availability}
                onChange={this.handleSelectChange.bind(
                  this,
                  "zone_availability"
                )}
                isMulti
                placeholder="Select Availabilty"
                options={this.props.availabiltyList.map((item) => {
                  return {
                    value: item.av_id,
                    label: item.av_name,
                  };
                })}
              />
              <label className="select-box-label">
                Availabilty<span className="error">*</span>
              </label>
              {errMsgAvail}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errMsgPostalCode !== "" &&
                  errMsgPostalCode !== false &&
                  errMsgPostalCode !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="zone_postal_code"
                id="zone_postal_code"
                value={fields.zone_postal_code}
                {...$field("zone_postal_code", (e) =>
                  onChange("zone_postal_code", e.target.value)
                )}
              />
              <label htmlFor="zone_postal_code">
                Postal Code<span className="error">*</span>
              </label>
              {errMsgPostalCode}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <Autocomplete
                sateValChange={this.sateValChange}
                placeholder="Enter your zone address."
                showError={errMssAddress}
                defaultValue={fields.zone_address_line1}
                required={true}
              />

              {/*  <input
                type="text"
                className={
                  errMssAddress !== "" &&
                  errMssAddress !== false &&
                  errMssAddress !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="zone_address_line1"
                id="zone_address_line1"
                value={fields.zone_address_line1}
                {...$field("zone_address_line1", (e) =>
                  onChange("zone_address_line1", e.target.value)
                )}
              />
              <label htmlFor="zone_address_line1">
                Address<span className="error">*</span>
              </label>
              {errMssAddress} */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errmMsgMinAmt !== "" &&
                  errmMsgMinAmt !== false &&
                  errmMsgMinAmt !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="zone_min_amount"
                value={fields.zone_min_amount}
                {...$field("zone_min_amount", (e) =>
                  onChange("zone_min_amount", e.target.value)
                )}
              />
              <label htmlFor="zone_min_amount">Minimum Amount</label>
              {errmMsgMinAmt}
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errmMsgDelivery !== "" &&
                  errmMsgDelivery !== false &&
                  errmMsgDelivery !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="zone_additional_charge"
                id="zone_additional_charge"
                value={fields.zone_additional_charge}
                {...$field("zone_additional_charge", (e) =>
                  onChange("zone_additional_charge", e.target.value)
                )}
              />
              <label htmlFor="zone_additional_charge">Additional Charge</label>
              {errmMsgDelivery}
            </div>
          </div> */}
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errmMsgFreeDelivery !== "" &&
                  errmMsgFreeDelivery !== false &&
                  errmMsgFreeDelivery !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="zone_free_delivery"
                id="zone_free_delivery"
                value={fields.zone_free_delivery}
                {...$field("zone_free_delivery", (e) =>
                  onChange("zone_free_delivery", e.target.value)
                )}
              />
              <label htmlFor="zone_free_delivery">Free Delivery</label>
              {errmMsgFreeDelivery}
            </div>
          </div>
          <div
            className={
              errMsgStatus !== "" &&
              errMsgStatus !== false &&
              errMsgStatus !== undefined
                ? "col-md-6 error-select error"
                : "col-md-6"
            }
          >
            <div className="form-floating form-floating-outline custm-select-box">
              <Select
                value={fields.status}
                onChange={this.handleSelectChange.bind(this, "status")}
                placeholder="Select Status"
                options={[
                  { value: "A", label: "Active" },
                  { value: "I", label: "In Active" },
                ]}
              />
              <label className="select-box-label">
                Status<span className="error">*</span>
              </label>
              {errMsgStatus}
            </div>
          </div>
          <div className="col-md-12">
            <iframe
              allow="true"
              src={`${siteMainURL}camppanel/zone/edit?zone_id=${
                this.props.editID
              }&map_random_id=${getReferenceID()}&company_id=${CompanyID()}`}
              title=""
              height="520"
              name="zonemap"
              id="zonemap"
              width="100%"
            ></iframe>
          </div>
          <div className="pt-1 pb-4 pt-1 text-end">
            <button
              type="button"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
            <Link to={"/clientpanel/zone"}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
Form = validated(validationConfig)(Form);
