import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import axios from "axios";
import { apiUrl, clientheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  clientID,
  CompanyID,
  isEmpty,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
var module = "clientpanel/userrole/";
var moduleName = "User Roles";
var modulePath = "/clientpanel/userroles";
class Form extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/clientpanel/userroles/edit/:EditID") {
      editID = this.props.match.params.EditID;
    }
    this.state = {
      editID: editID,
      pageloading: false,
      postdata: {
        usergroup_name: "",
        userModule: [],
        action: "add",
      },
      loading: true,
      formpost: [],
      companyDetail: [],
      moduleList: [],
    };
  }
  componentDidMount() {
    this.loadModule();
    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + CompanyID() + "&detail_id=" + this.state.editID,
        url: apiUrl + module + "details",
        type: "client",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost != nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success", "No");
          var history = this.props.history;
          setTimeout(function () {
            history.push(modulePath);
          }, 1000);
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          var clientupdatedata = {
            usergroup_name: result.usergroup_name,
            userModule: result.usergroup_module,
            action: "edit",
          };
          this.setState({ postdata: clientupdatedata, pageloading: false });
        } else {
          this.props.history.push(modulePath);
          showAlert("Error", "Invalid" + moduleName, "error");
        }
      });
    }
  }

  loadModule() {
    var urlShringTxt = apiUrl + module + "loadModule?company_id=" + CompanyID();
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ moduleList: res.data.result });
      }
    });
  }

  sateValChange = (field, value) => {};

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { postdata: { [field]: { $set: value } } })
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var postData = this.state.postdata;
    var userModule = postData.userModule;
    var updateduserModule = [];
    if (Object.keys(userModule).length > 0) {
      Object.keys(userModule).map((item) => {
        if (userModule[item].length > 0) {
          updateduserModule.push({ module: item, permisson: userModule[item] });
        }
      });
    }
    var postObject = {
      role_name: postData.usergroup_name,
      userModule:
        updateduserModule.length > 0 ? JSON.stringify(updateduserModule) : [],
      loginID: userID(),
      company_admin_id: clientID(),
      company_id: CompanyID(),
      action: postData.action,
    };
    var post_url = module + "add";
    if (postData.action === "edit" && this.state.editID !== "") {
      postObject["edit_id"] = this.state.editID;
      post_url = module + "update";
    }
    this.props.getFormPost(postObject, post_url, "client");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"userroles"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"}{" "}
                      {moduleName}
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={modulePath}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>

                <PostForm
                  {...this.props}
                  fields={this.state.postdata}
                  moduleList={this.state.moduleList}
                  onChange={this.fieldChange}
                  onValid={this.handleSubmit}
                  error_msg={this.state.error_msg}
                  onInvalid={() => {
                    console.log("Form invalid!");
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Form);

function validationConfig(props) {
  const { usergroup_name } = props.fields;

  return {
    fields: ["usergroup_name"],
    validations: {
      usergroup_name: [[isEmpty, usergroup_name]],
    },
  };
}

class PostForm extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }

  handleChangeCheckBox(moduleKey, type, event) {
    var userModule = this.props.fields.userModule;
    var updateduserModule = userModule;
    var availModule = 0;
    $("." + type + "-select-all").prop("checked", false);
    if (Object.keys(userModule).length > 0) {
      Object.keys(userModule).map((item) => {
        if (moduleKey === item) {
          if (updateduserModule[item].includes(type)) {
            var index = updateduserModule[item].indexOf(type);
            if (index > -1) {
              updateduserModule[moduleKey].splice(index, 1);
            }
          } else {
            updateduserModule[moduleKey].push(type);
          }
          availModule++;
        }
      });
    }
    if (event.target.checked === true && availModule === 0) {
      updateduserModule[moduleKey] = [];
      updateduserModule[moduleKey].push(type);
    }
    this.props.onChange("userModule", updateduserModule);
  }
  handleChangeCheckAll(type, event) {
    var updateduserModule = this.props.fields.userModule;
    if (this.props.moduleList.length > 0) {
      this.props.moduleList.map((item, index) => {
        if (event.target.checked) {
          $("." + type + "-checkbox").prop("checked", true);
        } else {
          $("." + type + "-checkbox").prop("checked", false);
        }

        if (
          updateduserModule[item.moduleKey] !== "" &&
          typeof updateduserModule[item.moduleKey] !== undefined &&
          typeof updateduserModule[item.moduleKey] !== "undefined"
        ) {
          if (event.target.checked) {
            updateduserModule[item.moduleKey].push(type);
          } else {
            var index = updateduserModule[item.moduleKey].indexOf(type);
            if (index > -1) {
              updateduserModule[item.moduleKey].splice(index, 1);
            }
          }
        } else {
          updateduserModule[item.moduleKey] = [];
          updateduserModule[item.moduleKey].push(type);
        }
      });
    }
    this.props.onChange("userModule", updateduserModule);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgName = "";
    if ($validation.usergroup_name.error.reason !== undefined) {
      errMsgName = $validation.usergroup_name.show && (
        <span className="error">{$validation.usergroup_name.error.reason}</span>
      );
    }

    return (
      <form className="card fv-plugins-bootstrap5" id="modulefrm">
        <div className="card-body row">
          <div className="col-md-6">
            <div className="form-floating form-floating-outline mb-4">
              <input
                type="text"
                className={
                  errMsgName !== "" &&
                  errMsgName !== false &&
                  errMsgName !== undefined
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="usergroup_name"
                value={fields.usergroup_name}
                {...$field("usergroup_name", (e) =>
                  onChange("usergroup_name", e.target.value)
                )}
              />
              <label htmlFor="usergroup_name">
                Role Name <span className="error">*</span>
              </label>
              {errMsgName}
            </div>
          </div>
          <div className="col-12">
            <h5>Role Permissions</h5>
            <div className="table-responsive">
              <table className="table table-flush-spacing">
                <tbody>
                  <tr>
                    <td className="text-nowrap fw-semibold">
                      Select All Module
                      <i
                        className="mdi mdi-information-outline "
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Allows a full access to all module"
                      ></i>
                    </td>
                    <td>
                      <div className="form-check me-3 me-lg-5">
                        <input
                          className="form-check-input view-select-all"
                          type="checkbox"
                          onChange={this.handleChangeCheckAll.bind(
                            this,
                            "view"
                          )}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check me-3 me-lg-5">
                        <input
                          className="form-check-input add-select-all"
                          type="checkbox"
                          onChange={this.handleChangeCheckAll.bind(this, "add")}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check me-3 me-lg-5">
                        <input
                          className="form-check-input edit-select-all"
                          type="checkbox"
                          onChange={this.handleChangeCheckAll.bind(
                            this,
                            "edit"
                          )}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check me-3 me-lg-5">
                        <input
                          className="form-check-input delete-select-all"
                          type="checkbox"
                          onChange={this.handleChangeCheckAll.bind(
                            this,
                            "delete"
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                  {this.props.moduleList.length > 0 &&
                    this.props.moduleList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-nowrap fw-semibold">
                            {item.moduleName}
                          </td>
                          <td>
                            <div className="form-check me-3 me-lg-5">
                              <input
                                className="form-check-input view-checkbox"
                                type="checkbox"
                                id={item.moduleKey + "_view_" + index}
                                onChange={this.handleChangeCheckBox.bind(
                                  this,
                                  item.moduleKey,
                                  "view"
                                )}
                                defaultChecked={
                                  fields.userModule[item.moduleKey] !== "" &&
                                  typeof fields.userModule[item.moduleKey] !==
                                    undefined &&
                                  typeof fields.userModule[item.moduleKey] !==
                                    "undefined" &&
                                  fields.userModule[item.moduleKey].includes(
                                    "view"
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item.moduleKey + "_view_" + index}
                              >
                                {" "}
                                View{" "}
                              </label>
                            </div>
                          </td>
                          <td>
                            {item.moduleKey !== "dashboard" &&
                              item.moduleKey !== "orders" && (
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input add-checkbox"
                                    type="checkbox"
                                    id={item.moduleKey + "_add_" + index}
                                    onChange={this.handleChangeCheckBox.bind(
                                      this,
                                      item.moduleKey,
                                      "add"
                                    )}
                                    defaultChecked={
                                      fields.userModule[item.moduleKey] !==
                                        "" &&
                                      typeof fields.userModule[
                                        item.moduleKey
                                      ] !== undefined &&
                                      typeof fields.userModule[
                                        item.moduleKey
                                      ] !== "undefined" &&
                                      fields.userModule[
                                        item.moduleKey
                                      ].includes("add")
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={item.moduleKey + "_add_" + index}
                                  >
                                    {" "}
                                    Add{" "}
                                  </label>
                                </div>
                              )}
                          </td>
                          <td>
                            {item.moduleKey !== "dashboard" &&
                              item.moduleKey !== "orders" && (
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input edit-checkbox"
                                    type="checkbox"
                                    id={item.moduleKey + "_edit_" + index}
                                    onChange={this.handleChangeCheckBox.bind(
                                      this,
                                      item.moduleKey,
                                      "edit"
                                    )}
                                    defaultChecked={
                                      fields.userModule[item.moduleKey] !==
                                        "" &&
                                      typeof fields.userModule[
                                        item.moduleKey
                                      ] !== undefined &&
                                      typeof fields.userModule[
                                        item.moduleKey
                                      ] !== "undefined" &&
                                      fields.userModule[
                                        item.moduleKey
                                      ].includes("edit")
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={item.moduleKey + "_edit_" + index}
                                  >
                                    {" "}
                                    Edit{" "}
                                  </label>
                                </div>
                              )}
                          </td>
                          <td>
                            {item.moduleKey !== "dashboard" &&
                              item.moduleKey !== "orders" && (
                                <div className="form-check">
                                  <input
                                    className="form-check-input delete-checkbox"
                                    type="checkbox"
                                    id={item.moduleKey + "_delete_" + index}
                                    onChange={this.handleChangeCheckBox.bind(
                                      this,
                                      item.moduleKey,
                                      "delete"
                                    )}
                                    defaultChecked={
                                      fields.userModule[item.moduleKey] !==
                                        "" &&
                                      typeof fields.userModule[
                                        item.moduleKey
                                      ] !== undefined &&
                                      typeof fields.userModule[
                                        item.moduleKey
                                      ] !== "undefined" &&
                                      fields.userModule[
                                        item.moduleKey
                                      ].includes("delete")
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={
                                      item.moduleKey + "_delete_" + index
                                    }
                                  >
                                    Delete
                                  </label>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="pt-1 pb-4 pt-1 text-end">
            <button
              type="button"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
            <Link to={modulePath}>
              <button
                type="reset"
                className="btn btn-label-secondary waves-effect"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}
PostForm = validated(validationConfig)(PostForm);
