/* global google */
import React from "react";
class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orderHandled: "" };
  }
  componentDidMount() {
    var input = document.getElementById("pac-input");
    var options = {
      componentRestrictions: { country: "id" },
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    var currnetThis = this;
    autocomplete.addListener("place_changed", function () {
      document.getElementById("location-error").style.display = "none";
      var place = autocomplete.getPlace();
      var latitude = place.geometry.location.lat();
      var longitude = place.geometry.location.lng();
      var countryName = "";
      var cityName = "";
      var postalCode = "";
      var stateName = "";
      if (
        place.address_components !== "" &&
        typeof place.address_components !== undefined &&
        typeof place.address_components !== "undefined"
      ) {
        place.address_components.map((item) => {
          if (item.types !== "") {
            if (item.types.indexOf("country") >= 0) {
              countryName = item.long_name;
            }
            if (item.types.indexOf("locality") >= 0 && cityName === "") {
              cityName = item.long_name;
            }
            if (item.types.indexOf("postal_code") >= 0 && postalCode === "") {
              postalCode = item.long_name;
            }
            if (
              item.types.indexOf("administrative_area_level_1") >= 0 &&
              stateName === ""
            ) {
              stateName = item.long_name;
            }
          }
        });
      }
      if (cityName === "") {
        cityName = countryName;
      }

      var addressdetails =
        countryName +
        "~~" +
        latitude +
        "~~" +
        longitude +
        "~~" +
        cityName +
        "~~" +
        postalCode +
        "~~" +
        stateName;
      currnetThis.props.sateValChange("address", addressdetails);
    });
  }

  render() {
    return (
      <div className="form-floating form-floating-outline address-autocomplete">
        <input
          type="text"
          className={
            this.props.showError !== "" &&
            this.props.showError !== false &&
            this.props.showError !== undefined
              ? "form-control is-invalid"
              : "form-control"
          }
          id="pac-input"
          placeholder=""
          name="location_address"
          defaultValue={this.props.defaultValue}
        />
        <label htmlFor="pac-input">
          Address{this.props.required && <span className="error">*</span>}
        </label>
        <div id="location-error"></div>
      </div>
    );
  }
}

export default Autocomplete;
