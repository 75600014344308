import React, { Component } from "react";
import $ from "jquery";

class PageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = { pageloading: false };
  }
  componentDidMount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.pageloading !== nextProps.pageloading) {
      this.setState({
        pageloading: nextProps.pageloading,
      });
    }
  }

  render() {
    return <div></div>;
  }
}

export default PageLoader;
