import cookie from "react-cookies";
export const appId = "01DF0942-0B19-4E95-8EBA-2BEFFD2EFFE9";
export const apiUrl = process.env.REACT_APP_API_URL;
export const siteMainURL = process.env.REACT_APP_SITE_MAIN_URL;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const CountryTxt = "Indonesia";
export const productNoImg = "/img/product-noimg.jpg";
export const adminlimit = "10";
var accesstoken = {
  Authorization: cookie.load("accessToken"),
};
export const masterheaderconfig = {
  headers: accesstoken,
};
var clientaccesstoken = {
  Authorization: cookie.load("clientAccessToken"),
};
export const clientheaderconfig = {
  headers: clientaccesstoken,
};
