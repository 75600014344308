import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import Select from "react-select";
import DatePicker from "react-datepicker";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { apiUrl, adminlimit, clientheaderconfig } from "../../Helpers/Config";
import { GET_LISTDATA } from "../../../actions";
import {
  removeItem,
  clientID,
  CompanyID,
  showAlert,
  showLoader,
  hideLoader,
} from "../../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Pagenation from "../Layout/Pagenation";
var module = "clientpanel/auditReports/";
var moduleName = "Audit Reports";
class List extends Component {
  constructor(props) {
    super(props);
    var companyID = cookie.load("companyID");
    this.state = {
      companyID: companyID,
      path: this.props.match.path,
      totalRecords: 0,
      totalPage: 0,
      currentPage: 1,
      listdata: [],
      loading: true,
      module: [
        {
          label: "All Module",
          value: "all",
        },
      ],
      users: "",
      audittype: [],
      source: "",
      errorFile: false,
      moduleList: [],
      userList: [],
      start_date: "",
      end_date: "",
    };
    this.handleChangeText = this.handleChangeText.bind(this);
  }
  componentDidMount() {
    this.loadList(1);
    this.listModule();
    this.loadUsers();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listdata !== this.state.listdata) {
      this.setState({
        listdata: nextProps.listdata,
        loading: false,
        totalRecords: nextProps.totalRecords,
        totalPage: nextProps.totalPages,
      });
    }
  }
  sateValChange = (field, value) => {
    if (field === "page") {
      this.setState(
        {
          loading: true,
          currentPage: value,
        },
        function () {
          this.loadList(value);
        }
      );
    }
  };

  listModule() {
    var urlShringTxt =
      apiUrl + module + "/dropdownlist?company_id=" + this.state.companyID;
    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        var moduleList = [{ label: "All Module", value: "all" }];
        if (res.data.result.length > 0) {
          res.data.result.map((item) => {
            moduleList.push({ label: item, value: item });
          });
        }
        this.setState({
          moduleList: moduleList,
        });
      }
    });
  }
  loadUsers() {
    var urlShringTxt =
      apiUrl +
      "clientpanel/users/dropdownlist?company_id=" +
      this.state.companyID;

    axios.get(urlShringTxt, clientheaderconfig).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ userList: res.data.result });
      }
    });
  }
  removeItem(deleteID) {
    var params = { delete_id: deleteID, company_id: this.state.companyID };
    var delurl = module + "delete";
    removeItem(params, delurl, "client");
  }
  handleChangeText(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }
  handleSelectChange(name, value) {
    this.setState({ [name]: value });
  }
  handleChangeDate(name, value) {
    this.setState({ [name]: value });
  }

  searchList() {
    this.setState({ loading: true }, function () {
      this.loadList(1);
    });
  }
  loadList(offset) {
    var addParams = "";
    if (this.state.users !== "" && this.state.users !== null) {
      addParams += "&users=" + this.state.users.value;
    }
    if (this.state.module.length > 0) {
      var module_List = [];
      this.state.module.map((item) => {
        module_List.push(item.value);
      });
      addParams += "&module=" + module_List.join(",");
    }
    if (this.state.audittype.length > 0) {
      var audittype_List = [];
      this.state.audittype.map((item) => {
        audittype_List.push(item.value);
      });
      addParams += "&audittype=" + audittype_List.join(",");
    }
    if (this.state.source !== "") {
      addParams += "&source=" + this.state.source.value;
    }
    if (this.state.start_date !== "" && this.state.end_date !== "") {
      addParams +=
        "&from_date=" +
        moment(this.state.start_date).format("Y-MM-DD HH:mm:ss") +
        "&to_date=" +
        moment(this.state.end_date).format("Y-MM-DD HH:mm:ss");
    } else if (this.state.start_date !== "" && this.state.end_date !== "") {
      addParams +=
        "&from_date=" +
        moment(this.state.start_date).format("Y-MM-DD HH:mm:ss");
    }
    /* if (this.state.email !== "") {
      addParams += "&email=" + this.state.email;
    }
    if (this.state.phone !== "") {
      addParams += "&phone=" + this.state.phone;
    } */

    var params = {
      params:
        "limit=" +
        adminlimit +
        "&offset=" +
        offset +
        "&company_id=" +
        this.state.companyID +
        addParams,
      url: apiUrl + module + "list",
      authType: "client",
    };
    this.props.getListData(params);
  }
  resetSearch() {
    this.setState(
      {
        loading: true,
        name: "",
        email: "",
        phone: "",
        storeID: "",
        status: "",
      },
      function () {
        this.loadList(1);
      }
    );
  }

  importCustomer() {
    $("#customermodal").modal("toggle");
  }

  confirmimportCustomer() {
    var formData = new FormData();
    var imagefile = document.querySelector("#cusatomer_file");
    if (imagefile.files.length === 0) {
      this.setState({ errorFile: true });
      return false;
    } else {
      this.setState({ errorFile: false });
    }

    showLoader("import_cust");
    formData.append("cusatomer_file", imagefile.files[0]);
    formData.append("company_admin_id", clientID());
    formData.append("company_id", CompanyID());
    axios
      .post(apiUrl + module + "import", formData, {
        headers: {
          Authorization: cookie.load("clientAccessToken"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        $("#customermodal").modal("toggle");
        hideLoader("import_cust");
        $("#cusatomer_file").val("");
        var errMsg =
          res.data.form_error !== "" ? res.data.form_error : res.data.message;
        if (res.data.status === "success") {
          showAlert("Success", errMsg, "success", "No");
        } else {
          showAlert("Error", errMsg, "error", "No");
        }
      });
  }

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"audit-reports"} />
          <div className="layout-page">
            <Topmenu />
            <a
              href={void 0}
              className="hidden"
              data-bs-toggle="modal"
              data-bs-target="#customermodal"
              id="openmodel"
            >
              Load
            </a>

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-9 col-md-6">
                    <h4 className="fw-bold">{moduleName}</h4>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.module}
                        onChange={this.handleSelectChange.bind(this, "module")}
                        placeholder="Select Module"
                        isClearable={true}
                        isMulti
                        options={this.state.moduleList}
                      />
                      <label className="select-box-label">Module</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.users}
                        onChange={this.handleSelectChange.bind(this, "users")}
                        placeholder="Select User"
                        isClearable={true}
                        options={this.state.userList}
                      />
                      <label className="select-box-label">User</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.audittype}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "audittype"
                        )}
                        placeholder="Select Type"
                        isClearable={true}
                        options={[
                          {
                            label: "Add",
                            value: "Add",
                          },
                          {
                            label: "Update",
                            value: "Update",
                          },
                          {
                            label: "Delete",
                            value: "Delete",
                          },
                        ]}
                        isMulti
                      />
                      <label className="select-box-label">Type</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline custm-select-box filter-select mb-4">
                      <Select
                        value={this.state.source}
                        onChange={this.handleSelectChange.bind(this, "source")}
                        placeholder="Select Source"
                        isClearable={true}
                        options={[
                          {
                            label: "Web",
                            value: "Web",
                          },
                          {
                            label: "App",
                            value: "App",
                          },
                        ]}
                      />
                      <label className="select-box-label">Source</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating form-floating-outline mb-4">
                      <DatePicker
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        dropdownMode="select"
                        className="form-control"
                        selected={this.state.start_date}
                        dateFormat="d-MM-yyyy h:mm aa"
                        placeholderText="Start Date"
                        onChange={this.handleChangeDate.bind(
                          this,
                          "start_date"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating form-floating-outline mb-4">
                      <DatePicker
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        dropdownMode="select"
                        className="form-control"
                        selected={this.state.end_date}
                        minDate={
                          this.state.start_date !== ""
                            ? this.state.start_date
                            : ""
                        }
                        dateFormat="d-MM-yyyy h:mm aa"
                        placeholderText="Start Date"
                        onChange={this.handleChangeDate.bind(this, "end_date")}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={this.searchList.bind(this)}
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-md-1 mt-2">
                    <button
                      type="reset"
                      className="btn btn-label-secondary waves-effect"
                      onClick={this.resetSearch.bind(this)}
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="card">
                  <div className="table-responsive text-nowrap p-1 mt-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Type</th>
                          <th>Module Name</th>
                          <th>Source</th>
                          <th>User Name </th>
                          <th>IP</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading === true ? (
                          <tr>
                            <td colSpan={7} align="center">
                              <div
                                className="spinner-border spinner-border-lg text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : this.state.listdata.length > 0 ? (
                          this.state.listdata.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <strong>{item.audit_description}</strong>
                                </td>
                                <td>{item.audit_action_type}</td>
                                <td>{item.audit_module_name}</td>
                                <td>{item.company_username}</td>
                                <td>{item.audit_action_via}</td>
                                <td>{item.audit_ip_address}</td>
                                <td>{item.audit_created_on}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={7}>
                              No {moduleName} Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagenation
                    params={{
                      totalRecords: this.state.totalRecords,
                      totalPage: this.state.totalPage,
                      currentPage: this.state.currentPage,
                    }}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
        <div
          className="modal fade"
          id="customermodal"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Import Customer</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className=" mb-4">
                  <div className="card-body mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating form-floating-outline mb-4">
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              Import Customer File
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="cusatomer_file"
                              name="cusatomer_file"
                            />
                            {this.state.errorFile === true && (
                              <span className="error">
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                        <a href="/files/customer.csv">Downlaod Sample File</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  id="import_cust"
                  onClick={this.confirmimportCustomer.bind(this)}
                >
                  Import
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var listdata = Array();
  var listdataStatus = "";
  var totalPages = 0;
  var totalRecords = 0;
  if (Object.keys(state.listdata).length > 0) {
    listdataStatus = state.listdata[0].status;
    if (state.listdata[0].status === "ok") {
      listdata = state.listdata[0].result;
      totalPages = state.listdata[0].totalPages;
      totalRecords = state.listdata[0].totalRecords;
    }
  }
  return {
    listdata: listdata,
    totalPages: totalPages,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListData: (datas) => {
      dispatch({ type: GET_LISTDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(List);
